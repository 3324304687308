import React, {Component} from 'react';
import {Button, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";

class Contact extends Component {

    render() {

        return <div className="page-container home">

            <Container>
                <Grid stackable className="ui grid stackable">

                    <Grid.Row>

                        <Grid.Column width={8}>

                            <h1>
                                Reach out
                            </h1>
                            <p>
                                Are you interested in joining our team, or are you interested in organizing your own
                                group travelling
                                {/*the Climate Train */}
                                to Glasgow &mdash; get in touch through <a
                                href="mailto:info@railtothecop.com">info@railtothecop.com</a>.

                            </p>

                            <h2>Press inquiries</h2>
                            <p>
                                For press inquiries, e-mail <a href="mailto:press@railtothecop.com">press@railtothecop.com</a> or call +31 6 144 80 371.
                            </p>

                        </Grid.Column>
                        <Grid.Column width={8}>
                            <h1>
                                Get involved
                            </h1>
                            <p>
                                Want to stay up to date? Subscribe to our newsletter below. We will send you a
                                monthly newsletter to inform you about our campaign.
                            </p>
                            <div id="mc_embed_signup">
                                <Form
                                    action="https://railtothecop.us4.list-manage.com/subscribe/post?u=2f57303ba0864c71ddbfa2faf&amp;id=86d3818282"
                                    method="post" id="mc-embedded-subscribe-form" name="mc-embedded-subscribe-form"
                                    class="validate" target="_blank" novalidate>
                                    <div id="mc_embed_signup_scroll">

                                        <div className="mc-field-group">
                                            <Input type="email" name="EMAIL" className="required email"
                                                   id="mce-EMAIL" placeholder="E-mail address"
                                                   style={{minWidth: '300px', marginRight: '10px'}}/>
                                            <Button type="submit" value="Subscribe" name="subscribe"
                                                    id="mc-embedded-subscribe" className="button">Subscribe</Button>
                                        </div>
                                        <div id="mce-responses" className="clear">
                                            <div className="response" id="mce-error-response"
                                                 style={{display: "none"}}/>
                                            <div className="response" id="mce-success-response"
                                                 style={{display: "none"}}/>
                                        </div>
                                        <div style={{position: "absolute", left: "-5000px"}} aria-hidden="true">
                                            <input type="text" name="b_2f57303ba0864c71ddbfa2faf_86d3818282"
                                                   tabIndex="-1" value=""/></div>
                                        <div className="clear">
                                        </div>
                                    </div>
                                </Form>
                            </div>
                            <br/>
                            <p>
                                Or follow our social media channels for more regular content:
                            </p>
                            <p>
                                <a className="ui labeled icon button facebook"
                                   href="https://www.facebook.com/pg/railtothecop"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <i className='ui icon facebook'/> Follow us on Facebook
                                </a>{" "}
                                <a className="ui labeled icon button instagram"
                                   href="https://www.instagram.com/railtothecop/"
                                   target="_blank"
                                   rel="noopener noreferrer">
                                    <i className='ui icon instagram'/> Follow us on Instagram
                                </a><br/><br/>
                            </p>

                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
        </div>;
    }
}


export default Contact;
