import React, {Component} from 'react';
import {Button, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";

class Mission extends Component {


    render() {

        return (
            <div className="page-container home">

                <Container>
                    <Grid stackable className="ui grid stackable">

                        <Grid.Row>

                            <Grid.Column width={16}>
                                <h1>
                                    The Mission of Rail to the COP
                                </h1>
                                <p>
                                    The Rail to the COP campaign promotes and facilitates the transition towards a
                                    sustainable travel future.
                                    {/*As its flagship initiative, Rail to the COP organises a*/}
                                    {/*Climate Train from Brussels to Glasgow, to connect and mobilize people on their way*/}
                                    {/*towards the international climate conference: COP26. */}
                                    The campaign facilitates
                                    dialogue between the industry leaders, climate activists, youth and policy makers,
                                    {/*onboard the Climate Train,*/}
                                    as well as in events before, during and after the COP.
                                </p>
                                <p>
                                    For a just transition to a sustainable travel future, we need to amplify and combine
                                    the diverse voices across our participants and collaborators
                                </p>

                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={9}>
                                <h2>
                                    What do we do?
                                </h2>

                                <h3>
                                    We include (young) people in international climate negotiations
                                </h3>
                                <p>
                                    International climate negotiations are moments where the future is decided. We
                                    believe
                                    in participatory processes and want to involve those that will be affected by what
                                    is or
                                    what is not decided in these negotiations. We therefore aim at bringing a diverse
                                    group
                                    of youth to COP26 in Glasgow and connect them with decision makers.
                                </p>
                                <h3>
                                    We facilitate constructive dialogue between policy makers, experts and youth
                                </h3>
                                <p>
                                    We bring together policy makers, experts and youth to form deep bonds and have
                                    profound
                                    dialogues to drastically change the travel and transport industry. We break down
                                    silos
                                    and collaboratively work towards sustainable and just traveling. We do this by
                                    bringing
                                    stakeholders together in events and workshops, specifically during the Climate
                                    Train.
                                </p>
                                {/*<h3>*/}
                                {/*    We organize a Climate Train to COP26 in Glasgow*/}
                                {/*</h3>*/}
                                {/*<p>*/}
                                {/*    In collaboration with the Dutch rail infrastructure manager ProRail, we organize a*/}
                                {/*    Climate Train from Brussels to UNFCCCs COP26 in Glasgow. The train will provide a*/}
                                {/*    physical space for dialogue and will bring participants of COP26 to the conference*/}
                                {/*    sustainably.*/}
                                {/*</p>*/}
                                <h3>
                                    We encourage and support regional initiatives to travel to COP26 sustainably
                                </h3>
                                <p>
                                    We dream of people from Europe (and beyond) traveling to COP26 by train. We invite
                                    and
                                    encourage youth from all over Europe to join us by setting up regional initiatives
                                    to
                                    travel to COP26 sustainably, or to join us in Brussel. We provide active support to
                                    those willing to set up a journey.
                                </p>
                                <h3>
                                    We campaign for an accelerated transition in the travel industry
                                </h3>
                                <p>
                                    Through (social) media campaigning we bring visibility to the issues in the travel
                                    industry. By organizing events before and during COP we will share expertise and
                                    showcase opportunities to accelerate the transition for a sustainable and just
                                    travel
                                    industry. Through talking with policymakers and industry leaders we push for
                                    institutional changes to make sustainable travel easier and more accessible, as well
                                    as
                                    regulate polluting travel practices.
                                </p>

                            </Grid.Column>

                            <Grid.Column width={7}>
                                <img src="resources/images/Map train Glasgow.jpg" />
                            </Grid.Column>

                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h2>
                                    What is the issue?
                                </h2>

                                <p>
                                    The demand for travel has risen across the globe following a higher living standard.
                                    People continuously desire to travel far and often, for a variety of reasons. We
                                    witness this trend in numerous industries, but it culminates in tourism.
                                    Transportation, whether for public, private or commercial means, is a fundamental
                                    aspect to travel. While trains were the main mode of international transportation
                                    within Europe in the mid-20th century, aviation has now taken the centre stage.
                                    Meanwhile, intercontinental travel has skyrocketed. According to the IPCC, the
                                    transport sector was already responsible for 23% of global energy related emissions
                                    in 2010; which has increased since. Low-carbon travel options are plentiful, all in
                                    varying degrees more climate-friendly compared to airplanes. These include trains,
                                    buses, bikes, sailboats, ferries, zeppelins, light rail and car sharing, amongst
                                    others. Travel emissions are a significant part of how we are heating our planet.
                                    Considering the severity of the climate crisis, it is undeniably concerning that the
                                    travel industry is not effectively reducing its emissions.
                                </p>

                                <h3>
                                    Aviation and the damage done
                                </h3>
                                <p>
                                    Despite becoming more efficient and technologically advanced, the aviation industry
                                    has doubled its emissions in the past 20 years. Aviation is responsible for at least
                                    2% of global CO2 emissions, but at least 5.5% of global warming when non-CO2 effects
                                    are taken into account, with estimates of up to 7%. Moreover, passenger numbers are
                                    expected to double within the next two decades, significantly intensifying the
                                    impacts of the industry. Due to aviation’s continuous growth of emissions, and the
                                    reduction of emissions in other sectors, it is estimated that aviation alone will
                                    emit 22% of global carbon emissions by 20504. Aviation’s effects on global warming
                                    are even more severe, since warming is based on much more than carbon emissions. The
                                    altitude at which the combination of greenhouse gases (including black carbon, water
                                    vapour, nitrogen oxides and sulphur dioxide) is emitted and the formation of
                                    contrails and cirrus clouds are ways in which aeroplanes’ emissions trap radiation
                                    in our atmosphere, heating up our planet. The International Panel on Climate Change
                                    (IPCC) estimates that the impact of aviation is 2.7 times the impact of its carbon
                                    emissions alone.5 While attempting to limit global warming to 1.5°C in an effort to
                                    withhold major repercussions to the natural world, we cannot further ignore the
                                    negative impacts of the travel industry.
                                </p>
                                <h3>
                                    Social injustices and false solutions
                                </h3>
                                <p>
                                    Beyond critiques of environmental damages, the travel industry is linked to numerous
                                    social injustices. Over 80% of the world’s population have never set foot on an
                                    airplane.
                                </p>
                                <p>
                                    While a minority have the privilege to fly, the majority do not. Yet, it is many of
                                    the world’s poorest that experience the worst consequences of climate change. The
                                    very infrastructure that makes this inequality possible is founded on similar
                                    dynamics of power. Countless airports have been built and expanded on the land of
                                    the unheard. On top of evictions and plain disregard of land rights, airports come
                                    with numerous health risks and noise pollution. These and countless others are
                                    stories shadowing the infinite growth model of the aviation industry. Damage done to
                                    the environment is legitimized by unrealistic carbon offsetting schemes. Far from a
                                    solution, these schemes are highly inefficient and insufficient, as well as being
                                    riddled with human rights violations and cases of land grabbing. Another proposed
                                    false solution is the use of biofuels, amongst other unfeasible techno-fixes, all of
                                    which do not respond to the urgency of the climate crisis and distract from the
                                    conversations we need to have.
                                </p>
                                <h3>
                                    Sustainable transport modes in the background
                                </h3>
                                <p>
                                    So, what makes taking an airplane so attractive compared to other modes of travel? A
                                    primary reason is that aviation does not require over-land infrastructure, and
                                    therefore, does not face connectivity issues across borders and between modes of
                                    transport. Missing links between different modes of low-carbon transport hamper the
                                    process of creating a single travel (transportation) system. Lack of data
                                    availability and data sharing across sustainable transport modes impairs
                                    international cooperation between operating companies. Countries further hinder this
                                    cooperation by enforcing restrictions on cross-border traffic or upholding
                                    incompatible infrastructure, such as rail tracks that do not match. For consumers,
                                    it therefore remains a challenge to choose sustainable transport modes over their
                                    climate-intensive counterpart.
                                </p>
                                <h3>
                                    How aviation is favoured
                                </h3>
                                <p>
                                    Next to practicalities, decision-making on aviation regulation (or lack thereof) has
                                    been short-sighted and prioritised economic benefits over environmental and social
                                    impacts. European aviation has been freed from commercial restrictions since 1992
                                    and currently benefits from facilitative political measures, both nationally and
                                    internationally.7 The industry enjoys competitive advantages over sustainable
                                    transportation modes, enabling it to outcompete more climate-friendly options even
                                    for short distances. The following are several systemic examples that emphasize the
                                    fundamental imbalance of the playing field for the travel industry, putting aviation
                                    ahead of alternatives:
                                </p>
                                <ul>
                                    <li>Policy measures: tax-exemptions for kerosene and VAT, emission allowances for
                                        aviation in the EU Emissions Trading System (ETS), exclusion of aviation from
                                        Nationally Determined Contributions (NDCs) in the Paris Agreement, lack of
                                        commitment to and exemption from policies on sustainable transportation.
                                    </li>
                                    <li>Lobby and legitimization of unfeasible techno-fixes: e.g. biofuels and carbon
                                        offsetting hailed as responsible ways to deal with travel emissions and continue
                                        unconstrained growth.
                                    </li>
                                </ul>
                                <h3>
                                    The effect on travel cultures
                                </h3>
                                <p>
                                    Through these and other facilitative measures, air travel has become rooted in
                                    peoples’ everyday lives. Widely accessible and affordable flight connections have a
                                    clear effect on travel habits and mentality. Frequent flyers, for business or
                                    leisure, view flying as inherent to travel, all the while disregarding the
                                    associated environmental and social impacts. People often justify air travel by
                                    portraying it as a need, whether that be for work, to escape stress or to have a
                                    yearly holiday. Moreover, the visibility of status through the likes of social
                                    media, in the workplace and within social circles creates social pressure to see
                                    more of the world or to further one's career. This perception has encouraged a
                                    culture of entitlement towards flying – that it is a person’s right. Regarding
                                    leisure travel, limited time incites people to fast travel and to focus on
                                    destination over journey. For businesses and institutions, time is intrinsically
                                    linked to productivity and the relatively low costs of flights make businesses
                                    barely reflect on the actual need of travel. All this results in a culture of
                                    climate-intensive travel.
                                </p>
                            </Grid.Column>

                        </Grid.Row>

                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h2>
                                    What do we want?
                                </h2>

                                <p>
                                    In order to avoid an irreversible climate crisis, the travel industry needs to
                                    drastically change course within the next decade. We are confident that this
                                    transformation is not only very possible, but that it entails great opportunities,
                                    innovation potential and a wide variety of benefits for society.
                                </p>

                                <h3>Vision for the future of travel</h3>
                                <blockquote>
                                    We imagine a world, in which travelling is always meaningful and respects planetary
                                    boundaries. We dream of a reality in which all participatory processes lead to bold
                                    action, fostering a fair and sustainable travel industry. We envision a future in
                                    which travel is a force for good and does no harm to the travellers themselves and
                                    the ecosystems and communities that welcome them.
                                </blockquote>
                                <ul>
                                    <li>
                                        Governments and institutions follow the scientific evidence and facilitate a
                                        global system change. Restrictive regulations on polluting travel and ambitious
                                        carbon goals are implemented and financial instruments and incentives are
                                        introduced leading to a dominantly low-impact sector with fair travel practices
                                        that are affordable for all.
                                    </li>
                                    <li>
                                        Transport companies and service providers pay for the polluting damages (of the
                                        past), fairly distribute their profits among their employees and ensure that
                                        their strategy includes the interests of local communities in popular travel
                                        destinations. Additionally, the necessary information for booking and planning
                                        sustainable trips is made accessible to the public, driving an increased demand
                                        for future-proof travelling.
                                    </li>
                                    <li>
                                        People choose ways that enable ecosystems to heal, cultures to flourish and
                                        employees of the travel industry to be treated fairly. Based on these new
                                        standards, future generations can still have the chance to experience the
                                        wonders of nature and our societies.
                                    </li>
                                </ul>
                            </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h2>
                                    Disclaimer
                                </h2>
                                <p>
                                    We are aware of the fact that, as a group of mostly Europeans, we come from a highly privileged position. Though our concerns are global, our vision and solutions towards sustainable travel have been created from an European perspective, and with an EU policy emphasis. However, we actively seek to learn from all ideas and perspectives on reshaping travel from around the world and incorporate them to our work as much as possible. Climate justice is a central value shaping our work. We strive to be a source of inspiration and an accelerating impulse, to collectively reshape the future of travel.
                                   </p><p>
                                    We know COP26 is still pretty far away, the world might look totally different in 10 months time. There are uncertainties at play that we are aware of and are anticipating. We do not know if COP26 will be open for observers and we do not know if Eurostar will be in financial health in November. Nevertheless we aim to move forward with the campaign as these uncertainties do not take away the need for change!

                                </p>

                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Container>

            </div>
        );
    }
}

export default Mission;
