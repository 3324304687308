import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_en from "./translations/en/common.json";
import common_de from "./translations/de/common.json";
import common_fr from "./translations/fr/common.json";
import common_es from "./translations/es/common.json";

export const languages = {
    'en': {
        tag: 'en',
        name: "English",
        translations: common_en
    },
    'de': {
        tag: 'de',
        name: "Deutsch",
        translations: common_de
    },
    'fr': {
        tag: 'fr',
        name: "Français",
        translations: common_fr
    },
    'es': {
        tag: 'es',
        name: "Español",
        translations: common_es
    },
};


// Initialize i18n
i18n
    .use(initReactI18next)
    .init({
        resources: languages,
        lng: 'en',
        ns: ["translations"],
        defaultNS: "translations",
        keySeparator: ".",
        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
