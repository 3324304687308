import React, {Component, useState} from "react";
import {Button, Card, Checkbox, Container, Dropdown, Form, Grid, Icon, Image, Label} from "semantic-ui-react";
import firebase from "firebase";
import FormIntro from "./form/intro";
import FormFAQ from "./form/faq";
import {Trans, useTranslation} from 'react-i18next';
import LanguagePicker from "../components/LanguagePicker";

const FormPage = () => {

    const [loading, setLoading] = useState(false);
    const [sent, setSent] = useState(false);
    const [numberOfTickets, setNumberOfTickets] = useState("1");
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [organization, setOrganization] = useState("");
    const [group, setGroup] = useState("");
    const [gender, setGender] = useState("");
    const [specialGroup, setSpecialGroup] = useState("");
    const [groupOther, setGroupOther] = useState("");
    const [dateOfBirth, setDateOfBirth] = useState("");
    const [country, setCountry] = useState("");
    const [motivation, setMotivation] = useState("");
    const [accreditation, setAccreditation] = useState("");
    const [terms, setTerms] = useState(false);

    const [languageCollapsed, setLanguageCollapsed] = useState(true);
    const toggleLanguage = () => setLanguageCollapsed(!languageCollapsed);

    const {t} = useTranslation();

    const groups = [
        ["youth", t("join.join-form-group-youth", "Youth")],
        ["ngo", t("join.join-form-group-ngo", "NGO representative"),],
        ["rail", t("join.join-form-group-rail", "Rail industry representative"),],
        ["official", t("join.join-form-group-official", "Institutional representative"),],
        ["academic", t("join.join-form-group-academic", "Researcher/academic/expert"),],
        ["media", t("join.join-form-group-media", "Media"),],
        ["other", t("join.join-form-group-other", "Other"),],
    ];

    const groupOptions = groups.map(g => {
        return {key: g[0], text: g[1], value: g[0]}
    });

    const submit = (e) => {
        e.preventDefault();

        if (email === "") {
            alert("Please fill in your e-mail address");
            return false;
        }

        setLoading(true);
        let db = firebase.firestore();
        let values = {
            name,
            email,
            organization,
            numberOfTickets,
            dateOfBirth,
            country,
            motivation,
            terms,
            group,
            specialGroup,
            gender,
            accreditation,
        };
        values.createdAt = new Date();
        values.source = "railtothecop.com";

        db.collection("applications").add(values)
            .then(function () {
                setLoading(false);
                setSent(true);
            })
            .catch(function (error) {
                alert("Something went wrong, please let us know through info@railtothecop.com");
            });

        return false;
    }

    return <Container>
        <br/>

        {/*<LanguagePicker didChange={toggleLanguage}/>*/}

        <h1>{t("join.title")}</h1>

        <FormIntro/>

        <blockquote>
            {/*{t("join.join-form-deadline")}*/}
            <strong>Sign ups have now closed.</strong> Send us an e-mail at info@railtothecop.com if you still want to join, and we'll let you know if additional spots become available.
        </blockquote>

        <br/><br/>
        {/*<Grid centered stackable>*/}
        {/*    <Grid.Column width={10}>*/}
        {/*        <Grid.Row>*/}

        {/*            <Card fluid>*/}
        {/*                <Card.Content>*/}
        {/*                    <h2>{t("join.join-form-heading")}</h2>*/}

        {/*                    <blockquote>*/}
        {/*                        {t("join.join-form-deadline")}*/}
        {/*                        /!*Note: Sign ups have not yet opened, watch our social channels to receive updates on the launch.*!/*/}
        {/*                    </blockquote>*/}

        {/*                    {sent ? <>*/}
        {/*                            <h3>Thanks for submitting your application! We will contact you soon.</h3>*/}
        {/*                        </> :*/}
        {/*                        <Form loading={loading} onSubmit={submit}>*/}
        {/*                            <Grid stackable>*/}
        {/*                                <Grid.Row columns={2}>*/}
        {/*                                    <Grid.Column>*/}
        {/*                                        <Form.Field>*/}
        {/*                                            <label>{t("join.join-form-name")}</label>*/}
        {/*                                            <input placeholder={t("join.join-form-name")} value={name}*/}
        {/*                                                   onChange={e => setName(e.target.value)}/>*/}
        {/*                                        </Form.Field>*/}
        {/*                                    </Grid.Column>*/}
        {/*                                    <Grid.Column>*/}
        {/*                                        <Form.Field>*/}
        {/*                                            <label>{t("join.join-form-email")}</label>*/}
        {/*                                            <input placeholder={t("join.join-form-email")} value={email}*/}
        {/*                                                   onChange={e => setEmail(e.target.value)}/>*/}
        {/*                                        </Form.Field>*/}

        {/*                                    </Grid.Column>*/}
        {/*                                </Grid.Row>*/}

        {/*                                <Grid.Row columns={2}>*/}
        {/*                                    <Grid.Column>*/}
        {/*                                        <Form.Field>*/}
        {/*                                            <label>{t("join.join-form-tickets")}</label>*/}
        {/*                                            <input placeholder={t("join.join-form-tickets")}*/}
        {/*                                                   value={numberOfTickets}*/}
        {/*                                                   onChange={e => setNumberOfTickets(e.target.value)}/>*/}
        {/*                                        </Form.Field>*/}
        {/*                                    </Grid.Column>*/}
        {/*                                    <Grid.Column>*/}
        {/*                                        <Form.Field>*/}
        {/*                                            <label>{t("join.join-form-organisation")}</label>*/}
        {/*                                            <input placeholder={t("join.join-form-organisation")}*/}
        {/*                                                   value={organization}*/}
        {/*                                                   onChange={e => setOrganization(e.target.value)}/>*/}
        {/*                                        </Form.Field>*/}
        {/*                                    </Grid.Column>*/}
        {/*                                </Grid.Row>*/}

        {/*                                <Grid.Row columns={1}>*/}
        {/*                                    <Grid.Column>*/}
        {/*                                        <Form.Field>*/}
        {/*                                            <label>{t("join.join-form-group")}</label>*/}
        {/*                                            <Dropdown*/}
        {/*                                                placeholder='Select a group'*/}
        {/*                                                fluid*/}
        {/*                                                selection*/}
        {/*                                                options={groupOptions}*/}
        {/*                                                value={group}*/}
        {/*                                                onChange={(_, data) => setGroup(data.value)}*/}
        {/*                                            />*/}
        {/*                                        </Form.Field>*/}
        {/*                                    </Grid.Column>*/}
        {/*                                </Grid.Row>*/}

        {/*                                {group === 'other' && <Form.Field>*/}
        {/*                                    <label>Please specify</label>*/}
        {/*                                    <input placeholder='Group' value={groupOther}*/}
        {/*                                           onChange={e => setGroupOther(e.target.value)}/>*/}
        {/*                                </Form.Field>}*/}
        {/*                                {(group === "youth" || group === "ngo" || group === "other") && <>*/}

        {/*                                    <Grid.Row columns={2}>*/}
        {/*                                        <Grid.Column>*/}
        {/*                                            <Form.Field>*/}
        {/*                                                <label>{t("join.join-form-birthdate")}</label>*/}
        {/*                                                <input placeholder='dd/mm/yyyy' value={dateOfBirth}*/}
        {/*                                                       onChange={e => setDateOfBirth(e.target.value)}/>*/}
        {/*                                            </Form.Field>*/}
        {/*                                        </Grid.Column>*/}
        {/*                                        <Grid.Column>*/}
        {/*                                            <Form.Field>*/}
        {/*                                                <label>{t("join.join-form-gender")}</label>*/}
        {/*                                                <input placeholder={t("join.join-form-gender")} value={gender}*/}
        {/*                                                       onChange={e => setGender(e.target.value)}/>*/}
        {/*                                            </Form.Field>*/}
        {/*                                        </Grid.Column>*/}
        {/*                                    </Grid.Row>*/}

        {/*                                    <Grid.Row columns={1}>*/}
        {/*                                        <Grid.Column>*/}
        {/*                                            <p>*/}
        {/*                                                <small style={{color: "#000"}}>*/}
        {/*                                                    {t("join.join-form-underrepresented-groups-copy")}*/}
        {/*                                                </small>*/}
        {/*                                            </p>*/}
        {/*                                            <Form.Field>*/}
        {/*                                                <label>{t("join.join-form-underrepresented-groups")}</label>*/}
        {/*                                                <input*/}
        {/*                                                    placeholder={t("join.join-form-underrepresented-groups-placeholder", 'Do you identify as BIPOC / LGBTQI+ or another underrepresented group?')}*/}
        {/*                                                    value={specialGroup}*/}
        {/*                                                    onChange={e => setSpecialGroup(e.target.value)}/>*/}
        {/*                                            </Form.Field>*/}
        {/*                                        </Grid.Column>*/}
        {/*                                    </Grid.Row>*/}

        {/*                                    <Grid.Row columns={1}>*/}
        {/*                                        <Grid.Column>*/}
        {/*                                            <Form.Field>*/}
        {/*                                                <label>{t("join.join-form-country")}</label>*/}
        {/*                                                <input placeholder={t("join.join-form-country")} value={country}*/}
        {/*                                                       onChange={e => setCountry(e.target.value)}/>*/}
        {/*                                            </Form.Field>*/}
        {/*                                        </Grid.Column>*/}
        {/*                                    </Grid.Row>*/}
        {/*                                </>}*/}

        {/*                                {(group === "youth" || group === "ngo" || group === "other" || group === "media") && <>*/}

        {/*                                    <Grid.Row columns={1}>*/}
        {/*                                        <Grid.Column>*/}
        {/*                                            <Form.Field>*/}
        {/*                                                <label>{t("join.join-form-motivation")}</label>*/}
        {/*                                                <textarea*/}
        {/*                                                    placeholder={t("join.join-form-motivation-placeholder", 'Why do you want to join the climate train?')}*/}
        {/*                                                    value={motivation}*/}
        {/*                                                    onChange={e => setMotivation(e.target.value)}/>*/}
        {/*                                            </Form.Field>*/}
        {/*                                        </Grid.Column>*/}
        {/*                                    </Grid.Row>*/}
        {/*                                </>}*/}

        {/*                                {(group === "youth" || group === "ngo" || group === "other" || group === "media") && <>*/}

        {/*                                    <Grid.Row columns={1}>*/}
        {/*                                        <Grid.Column>*/}
        {/*                                            <Form.Field>*/}
        {/*                                                <label>{t("join.join-form-accreditation")}</label>*/}
        {/*                                                <input*/}
        {/*                                                    placeholder={t("join.join-form-accreditation-placeholder", 'Will you be able to arrange accreditation for COP?')}*/}
        {/*                                                    value={accreditation}*/}
        {/*                                                    onChange={e => setAccreditation(e.target.value)}/>*/}
        {/*                                            </Form.Field>*/}
        {/*                                        </Grid.Column>*/}
        {/*                                    </Grid.Row>*/}
        {/*                                </>}*/}
        {/*                            </Grid>*/}
        {/*                            <br/>*/}
        {/*                            <Form.Field>*/}
        {/*                                <Checkbox label={<label>*/}
        {/*                                    <Trans i18nKey="join.join-form-privacy-policy">*/}
        {/*                                        I agree to the <a href={"/privacy-policy"} target={"_blank"}>Privacy*/}
        {/*                                        Policy</a>*/}
        {/*                                    </Trans>*/}
        {/*                                </label>}*/}
        {/*                                          checked={terms}*/}
        {/*                                          onChange={(_, checkbox) => setTerms(checkbox.checked)}/>*/}
        {/*                            </Form.Field>*/}
        {/*                            <Button type='submit' primary>{t("join.join-form-submit", "Submit")}</Button>*/}

        {/*                        </Form>}*/}
        {/*                    <Image src="/resources/images/train-2.jpg" style={{marginTop: '0px'}} floated="right"/>*/}
        {/*                </Card.Content>*/}
        {/*            </Card>*/}

        {/*            <br style={{clear: "both"}}/>*/}
        {/*        </Grid.Row>*/}
        {/*    </Grid.Column>*/}
        {/*</Grid>*/}

        <FormFAQ/>

        <p>
            <Trans i18nKey="join.join-other-questions">
                Do you have any other question? Please don't hesitate to reach out to us at <a
                href="mailto:info@railtothecop.com">info@railtothecop.com</a>.
            </Trans>
        </p>
        <p>
            For press inquiries, e-mail <a href="mailto:press@railtothecop.com">press@railtothecop.com</a> or call +31614480371.
        </p>

    </Container>;
}

export default FormPage;