import React, { useEffect, useState, useContext } from "react";
import i18n from "i18next";
import {useLocation} from "react-router-dom";
const storageKey = 'rttc-language';

const initialLanguageState = "en"; //window.localStorage.getItem(storageKey) ?? "en";

export const LanguageContext = React.createContext({
        language: initialLanguageState,
        setLanguage: (_) => {},
        availableLanguages: [],
        setAvailableLanguages: (_) => {},
    }
);

export const useLanguage = () => useContext(LanguageContext);

function useQuery() {
    return new URLSearchParams(useLocation().search);
}

export default ({ children }) => {
    const [ language, _setLanguage ] = useState(initialLanguageState);
    const [ availableLanguages, _setAvailableLanguages ] = useState([initialLanguageState]); //, "es", "fr"

    let query = useQuery();

    const setLanguage = (language) => {
        i18n.changeLanguage(language);
        _setLanguage(language);
    }

    const setAvailableLanguages = (languages) => {
        let sortedLanguages = languages.sort();
        if (availableLanguages.length != languages.length ||
            !(availableLanguages.sort().every((value, index) =>
                value === sortedLanguages[index]))
        ) {
            _setAvailableLanguages(languages);
        } else if(languages.length === 0 || languages.indexOf(language) === -1) {
            if (initialLanguageState != language) {
                setLanguage(initialLanguageState); // Or only available language?
            }
        }
    }

    const requestedLanguage = query.get("lng") || initialLanguageState;
    if (requestedLanguage != language) {
        setLanguage(requestedLanguage);
    }

    // useEffect(() => {
    //     localStorage.setItem(storageKey, language);
    // }, [ language ]);

    const contextValue = {
        language,
        setLanguage,

        availableLanguages,
        setAvailableLanguages,
    };

    return <LanguageContext.Provider value={contextValue}>
        { children }
    </LanguageContext.Provider>
};