import React, {Component} from 'react';
import {Button, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";

class About extends Component {


    render() {

        return (
            <div className="page-container home">

                <Container>
                    <Grid stackable className="ui grid stackable">

                        <Grid.Row>

                            <Grid.Column width={16}>
                                <h1>
                                    The Mission of Rail to the COP
                                </h1>
                                <p>
                                    The Rail to the COP campaign promotes and facilitates the transition towards a
                                    sustainable travel future. {/*As its flagship initiative, Rail to the COP organises a
                                    Climate Train from Amsterdam to Glasgow, to connect and mobilize people on their way
                                    towards the international climate conference: COP26.*/} The campaign facilitates
                                    dialogue between the rail industry, climate activists, youth and policy makers.{/*}
                                    onboard the Climate Train through Amsterdam, Brussels, London to Glasgow, as well as
                                    in events before, after and at the COP.*/}
                                </p>
                                <p>
                                    For a just transition to a sustainable travel future, we need to amplify and combine
                                    the diverse voices across our participants and collaborators
                                </p>

                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Container>

            </div>
        );
    }
}

export default About;
