import React, {Component} from 'react';
import Home from "../home";
import {Grid, Image} from "semantic-ui-react";

class Team extends Component {

    render() {

        let {summary} = this.props;

        let persons = [

            {
                name: "Santeri Lehtonen",
                info: "Age: 30 | Nationality: Finnish | Current Location: Berlin, Germany |\n" +
                    "Education: MSc Environmental Sciences, University of Copenhagen |\n" +
                    "Current Occupation: Impacts Consultant, Self-Employed |\n" +
                    "Favourite train journey: From Budapest to Sofia via Belgrade. Awesome sceneries, fun old-stock carriages, exciting little kiosk-items on rural platforms and fun people all around. I’m also a huge fan of the ÖBB Berlin to Budapest night train",
                text: "Santeri is a Berlin-based impacts consultant helping citizens to seize the levers for change through social, technological and economic innovation. Tired of seeing governments and corporation pushing deadlines, he works with Impact Entrepreneurship, Life Cycle Assessments and Climate Action, with a special interest in Sustainable Food Systems, Slow Travel and Science Outreach. Santeri also facilitates workshops in Impact Forecast and gives talks in e.g. Greenwashing and Impact Entrepreneurship. His current pet project: vojo, is fixing  the issues in long-distance slow travel booking in Europe. In his free time Santeri likes to eat mixed nuts.",
                email: "santeri@railtothecop.com",
                image: "Santeri.jpg",
            },
            {
                name: "Lieke Brackel",
                info: "Age: 25 | Nationality: Dutch | Current Location: Amsterdam / The Hague | Education: PhD candidate TU Delft Ethics of Climate Adaptation | Favourite train journey: Swiss trains around the Bodensee!\n",
                text: "Lieke is a PhD candidate at the TU Delft. Her research is about questions of justice that arise in long-term climate adaptation in coastal areas. Besides, she is a contributing author to the 6th IPCC Assessment Report, Working Group II: Impacts, Adaptation, and Vulnerability, CH4: water. Since we cannot ‘adapt’ our way out of the climate crisis, Lieke is glad to work on climate mitigation action as well together with the Rail to the COP team. Last year, Lieke also was a representative of Sail to the COP during COP25 in Madrid. Lieke loves rivers, coastal wetlands, sailing, and hiking. Her recommendation to discover both the awesomeness of water and trains: follow the Rhine river from sea to source by rail!",
                email: "lieke@railtothecop.com",
                image: "Lieke.jpg",
            },
            {
                name: "Isabel Seeger",
                info: "Age: 26 | Nationality: German | Current Location: Berlin |\n" +
                    "Education: MA in Environmental Politics and Political Science at Sciences Po Paris & FU Berlin |\n" +
                    "Current Occupation: Research Fellowship with Ecologic Institute on water and marine issues |\n" +
                    "Favourite train journey: The night train from Paris to Briancon",
                text: "As a recent graduate of Environmental Politics at Sciences Po Paris & FU Berlin, Isabel is currently working at Ecologic Institute on Berlin marine and water issues. She thinks that we need research to contribute to a deeper understanding of the challenges we are facing, but also believes in the importance of taking action now! Isabel enjoys giving guided canoe tours on Berlin’s waterways, listening to podcasts, playing beachvolleyball and climbing, learning languages and planning train itineraries for the post-pandemic era.",
                email: "isabel@railtothecop.com",
                image: "Isabel.jpg",
            },
            {
                name: "Mara de Pater",
                info: "Age: 26 | Nationality: Dutch | Current Location: Amsterdam |\n" +
                    "Education: MSc Environmental Policy and MSc Anthropology |\n" +
                    "Current Occupation: Researcher & Advisor at Dutch Research Institute for Transitions (DRIFT) |\n" +
                    "Favourite train journey: the night train between Bratislava and Prague. Something cozy about these old night trains.",
                text: "Mara works as a researcher and advisor for the Dutch Research Institute for Transitions (DRIFT) in Rotterdam and focuses on linkages between (de-)democratic processes and sustainability transitions. In her climate advocacy she focusses on speeding up the transition away from a fossil society to a more democratic and sustainable one. She was one of the founders of Sail to the COP and is building on that work with Rail to the COP. Besides research and climate actions she loves being outside in any possible way and has taste and skill for creating and munching on good food.",
                email: "mara@railtothecop.com",
                image: "Mara.jpg",
            },
            {
                name: "Lukas Kiefer",
                info: "Age: 24 | Nationality: German | Current Location: Eberswalde, Germany | Education: B. Eng. Renewable Energies |\n" +
                    "Favourite train journey: I love the train ride between my home town Cologne and Berlin.",
                text: "Lukas finished his bachelor studies last summer. He is currently in the middle between the bachelor and a master and wants to focus on his work in the climate movement. He wants to create more awareness and educate people about the impact of the aviation sector on our planet.\n" +
                    "Lukas just moved to Eberswalde and loves to explore the nature. After a long day outside he enjoys playing guitar and challenges his friends in online board games.",
                email: "lukas@railtothecop.com",
                image: "Lukas.jpg",
            },
            {
                name: "Jeppe Bijker",
                info: "Age: 29 | Nationality: Dutch | Current Location: Amsterdam |\n" +
                    "Education: MSc Design for Interaction, Delft University of Technology |\n" +
                    "Current Occupation: Entrepreneur, designer & software engineer |\n" +
                    "Favourite train journey: One of the highest railways in Europe: the Gornergrat bahn, ending at 3089 meters elevation, offers breathtakingly beautiful views of the Swiss alps.",
                text: "After graduating as a design engineer, Jeppe wanted to combine his passions for travelling and sustainability as well as possible. If it's possible to inspire and activate a group of young people on the way, Jeppe won't stop until the ship departs to South America, or the train leaves for Glasgow. As a designer and programmer of drone shows, he also works on better alternatives to fireworks, painting magical images on the night skies.",
                email: "jeppe@railtothecop.com",
                image: "Jeppe.jpg",
            },
            {
                name: "Jan Feist",
                info: "Age: 24 | Nationality: German & Polish | Current Location: Wageningen / Amsterdam | Education: BSc Earth and Environmental Sciences, Amsterdam University College | Current Occupation: Student, MSc Organic Agricultre, Wageningen University & Research |\n" +
                    "Favourite train journey: The first and only night train I have taken was from Kraków to Budapest, it was magical. Waking up in the heart of a bustling city must be the closest to teleportation we can get.",
                text: "Jan studied a bit of everything during his Liberal Arts & Science degree (mainly rocks and aerosols) in Amsterdam. After four years of urban life, he recently moved to the countryside to study Agroecology in Wageningen. He is interested in how food systems can catalyse the sustainability transition. Since he joined Sail to the COP he has become obsessed with night trains. Other obsessions include mushrooms, sourdough baking and house plants.",
                email: "jan@railtothecop.com",
                image: "Jan.jpg",
            },
            {
                name: "Rosa Hofgärtner",
                info: "Age: 26 | Nationality: Dutch / German | Current Location: Ghent in Belgium Education: MA Environmental Policy & Journalism |\n" +
                    "Current Occupation: Freelance Journalist Climate & Democracy and founder of ExPlane |\n" +
                    "Favourite train journey: Night train from Paris to Milano",
                text: "During her studies in Environmental Policy in Paris, Rosa mainly focused on climate scepticism and hostility towards climate policy to understand what hampers the ecological transition and could help to accelerate it. Now, she continues her interest in the intersection between the climate crisis and democracy as a freelance journalist. With her involvement in several initiatives, such as Rail to the COP and ExPlane, Rosa hopes to not only write about but also actively push for the transformation to a just and low-carbon society. She spends her weekends transforming a container into a tiny house from second-hand and sustainable materials, and also loves to take photos.",
                email: "rosa@railtothecop.com",
                image: "Rosa.jpg",
            },
            {
                name: "Jakob Hartmann",
                info: "Age: 23 | Nationality: German | Current Location: Amsterdam | Education: Completing a MSc in Environmental Geography | Current Occupation: Student |\n" +
                    "Favourite train journey: Berlin to Prague",
                text: "Jakob is currently starting his Masters thesis at the University of Amsterdam on the topic of degrowth and land use change. He has been involved in climate activism for the past few years and attended the 2019 Conference of Parties in Madrid with a group of 25 youth activists. He’s an avid cyclist and if we weren’t organizing a train to Glasgow he would have liked to cycle to the COP 🚴",
                email: "jakob@railtothecop.com",
                image: "Jakob.jpg",
            },
            {
                name: "Leo Baumgärtner",
                info: "Age: 24 | Nationality: German | Current Location: Berlin | Education: BSc in Liberal Arts with Major in Earth, Energy & Sustainability LUC The Hague | Current Occupation: Apprentice Chef | Favorite Train Journey: The Albula Line, Switzerland",
                text: "After finishing his Bachelor Studies in The Hague in the Summer of 2019, Leo moved on to explore the Green Tech Start Up Scene in Berlin. After a year of desk work, he has recently stepped out of the office and is now working as a chef. Besides experimenting with plant-based food, Leo enjoys engaging in various forms of climate activism, as well as playing volleyball and acting.",
                email: "leo@railtothecop.com",
                image: "Leo.jpg",
            },
            {
                name: "Erika van der linden",
                info: "Age: 25 | Nationality: Dutch | Current Location: The Hague, The Netherlands | Education: Sustainable technology policy consultant and PhD candidate Sustainable Innovation | Favourite train journey: in one day from The Hague to Barcelona, loved it! Also great train ride is the one all along the Italian East coast from North to South, with the weather getting warmer and the scenery only gets more beautiful.",
                text: "Erika works as a policy consultant for Technopolis. In this role, she advices national and intranational governments on topics such as sustainable technology, the green economy and the energy transition. One day a week she works on a PhD thesis on the role of the private sector in obtaining the SDGs. Specifically, she researches how trade-offs between SDGs can be avoided, such as environmental pollution from progress on increase in renewable energy. She searches for policies that can bring about synergies between SDGs, across the Global North as well as the Global South. On the weekends, she can be found singing to her vegetable plants in her garden, which then give pay her back by providing the inputs for good homemade food!",
                email: "erika@railtothecop.com",
                image: "Erika.jpg",
            },
            {
                name: "Tanja Rakocevic",
                info: "Age: 25 | Nationality: German/Montenegrin | Current location: Eberswalde, Germany | Education: BSc Environmental and Sustainability Studies, Leuphana University of Lüneburg | Current occupation: MSc student in Rural Development and Nature Conservation | Favourite Train Journey: Bar-Podgorica (Montenegro)",
                text: "Tanja is a MSc student who grew up in Germany but has her roots in the Balkans. After finishing her degree in Environmental and Sustainability Studies she moved to Montenegro to tackle the waste management issue there. She is currently doing her Master degree in Rural Development and Nature Conservation in a small town near Berlin and has been involved in environmental activism for a few years now. She loves slow and sustainable travel and wants to encourage more people to move that way.",
                email: "tanja@railtothecop.com",
                image: "Tanja.jpg",
            },


        ].sort((a, b) => a.name.localeCompare(b.name))

        return summary ?
            <div className="team">
                {persons.map(person =>
                    <a href="/about/team">
                        <Image src={"/resources/images/team/" + person.image} className="person"/>
                    </a>)}
            </div>
            : <>

                <Grid className="team expanded">
                    <Grid.Row>
                        {persons.map(person => <Grid.Column width={8}>
                            <Image src={"/resources/images/team/" + person.image} className="person"/>

                            <h3>{person.name}</h3>
                            <p>{person.info}</p>

                            <p>{person.text}</p>
                            <a href={"mailto:" + person.email}>{person.email}</a><br/><br/>

                        </Grid.Column>)}
                    </Grid.Row>
                </Grid>


            </>;

    }
}


export default Team;