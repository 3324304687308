import React, {Component, useState} from "react";
import {
    Accordion,
    Button,
    Card,
    Checkbox,
    Container,
    Dropdown,
    Form,
    Grid,
    Icon,
    Image,
    Label
} from "semantic-ui-react";
import firebase from "firebase";
import FormIntro from "./form/intro";
import FormFAQ from "./form/faq";
import {Trans, useTranslation} from 'react-i18next';
import LanguagePicker from "../components/LanguagePicker";

let questions = [
    [
        <>Tickets & Cancellation</>,
        [
            [
                <>
                    How can I cancel my ticket
                </>,
                <p>
                    Please send an email to <a href={"mailto:amsterdam@eurostar.com"}>amsterdam@eurostar.com</a> in
                    which you provide the Booking ID, and full name of the people you want to cancel. Please keep in
                    mind that cancelling your Climate Train Ticket automatically cancels your return ticket, too.
                </p>
            ],
            [
                <>
                    Can I give my ticket to someone else?
                </>,
                <p>
                    You can transfer your ticket to another person by sending an email to <a
                    href={"mailto:lennart@railtothecop.com"}>lennart@railtothecop.com</a>a>. Please provide the Booking
                    ID and full name of the relevant ticket, as well as the full name and email address of the person
                    you want to transfer the ticket to. Importantly, the printed name on the ticket will stay the same,
                    but the Eurostar team will know about the changes.
                </p>
            ],
            [
                <>
                    May I board the Climate Train in a different train station than the one written on my ticket?

                </>,
                <p>
                    As train station capacities are limited, it is essential that you do not board from a different
                    train station than is written on your ticket without having communicated with the Rail To The COP
                    team beforehand. The easiest way to check if you can change your departure station is by filling in
                    the questionnaire we sent you by email with our info-wagon #2.
                </p>
            ],
            [
                <>
                    How can I book my return ticket

                </>,
                <p>
                    You have to arrange your return trip yourself but can get a discount for any train that goes
                    from London to Brussels, Rotterdam or Amsterdam between 1/11 and 19/11. To get this discount,
                    you need to book via the Aria link that we sent you via email.

                </p>
            ],
        ],
    ],
    [
        <>The Climate Train & Rail to the COP
        </>,
        [
            [
                <>
                    What exactly is going to happen on the train?
                </>,
                <p>
                    The on-board program was designed to spark meaningful dialogue between youth, civil society,
                    industry and policy. The aim is to build cross-sector alliances that accelerate the transition
                    towards fair and sustainable travel and make the connection with the struggle for climate justice at
                    COP26. The on-board programme enroute from Amsterdam to London will feature workshops led by NGOs,
                    industry, and policy, which will generate ideas, share knowledge, and build personal connections.
                    Finally, the outcomes of the workshops will be synthesised and ready for printing on one-pagers when
                    we arrive in Glasgow.
                </p>
            ],
            [
                <>
                    Can I join the train but not participate in the programme?

                </>,
                <p>
                    Yes. Please indicate this preference in the survey we sent you by email in the info-wagon #2. Since
                    space in the non-programme coaches is limited, we can not guarantee that you will get a spot there.

                </p>
            ],
            [
                <>
                    Is accommodation in Glasgow or Amsterdam included in the ticket for the Climate Train?

                </>,
                <p>
                    You have to arrange accommodation in Glasgow yourself, but we do offer a place to stay in Amsterdam
                    from the 29th - 30th October. Together with the LCOY NL team and Greenpeace, you can sign-up for a
                    sleeping-place in one of the Amsterdam Greenpeace offices. Simply send an email to
                    amsterdam-homestay@railtothecop.com, in which you include your full name, booking ID, and, if you
                    like, your phone number. If you want to sign-up for several people, please indicate who will be the
                    contact person for the group. In addition, feel free to join our slack channels via the link we sent
                    you with the info-wagon #3.
                </p>
            ],
            [
                <>
                    Can I enter COP with the Climate Train ticket?

                </>,
                <p>
                    Nope.
                </p>
            ],
            [
                <>
                    Can I leave the Climate Train in Rotterdam or Brussels?

                </>,
                <p>
                    No. If you join the Climate Train or any other Eurostar connection to London, you cannot leave
                    before reaching the UK.

                </p>
            ],
        ],
    ],
    [
        <>Financial Support
        </>,
        [
            [
                <>
                    I can’t afford a ticket, can my ticket be sponsored by Rail to the COP?
                </>,
                <p>Everyone who cannot afford to pay the full train ticket to go to COP26 with the Climate Train can
                    ask to get their ticket sponsored. We want to make the reimbursement as easy as possible.
                    Unfortunately you have to pay the ticket in the first place – due to technical reasons. We are
                    working with OpenCollective to be able to reimburse the money as soon as possible.</p>
            ],
            [
                <>
                    How can my ticket be sponsored?

                </>,
                <p>
                    Please have a look at our <a href={"https://docs.google.com/document/d/1Roam-Jql5GdvtOIes-xowIbmRYfr1eQXjaZlOc5Ziy0/edit?usp=sharing"} target={"_blank"}>Step-by-step guide</a>. The process is explained there.

                </p>
            ],
            [
                <>
                    I love your project! How can I support you with a donation?

                </>,
                <p>
                    You can donate on our OpenCollective Website: <a href={"https://opencollective.com/rail-to-the-cop"} target={"_blank"}>https://opencollective.com/rail-to-the-cop</a>

                </p>
            ],
        ],
    ],
];


const ParticipantSupport = () => {

    const {t} = useTranslation();

    const [activeIndex, setActiveIndex] = useState(-1);
    const handleClick = (_, {index}) => {
        setActiveIndex(index === activeIndex ? -1 : index)
    }

    return <Container>
        <br/>

        <h1>{t("support.title")}</h1>

        <p>You booked your ticket and are now planning your journey to Glasgow and back? Here you can find everything
            you need to know.</p>

        <p style={{textAlign: 'center'}}>
            <a href={"https://www.prorail.nl"} target="_blank">
                <img src="resources/images/logos/prorail.svg" style={{width: '170px', marginRight: '40px'}}
                     alt="ProRail"/>
            </a>
            <a href={"https://www.eurostar.com"} target="_blank">
                <img src="resources/images/logos/eurostar.png" style={{width: '160px', marginRight: '40px'}}
                     alt="ProRail"/>
            </a>
            <a href={"https://www.ns.nl"} target="_blank">
                <img src="resources/images/logos/ns.svg" style={{width: '80px', marginRight: '40px'}}
                     alt="NS"/>
            </a>
            <a href={"https://avantiwestcoast.co.uk"} target="_blank">
                <img src="resources/images/logos/avanti.png" style={{width: '150px', marginRight: '40px'}}
                     alt="Avanti"/>
            </a>
        </p><br/>
        <p style={{textAlign: 'center'}}>
            <div style={{marginBottom: '5px'}}><strong>Supported by</strong></div>
            <a href={"http://ews-schoenau.de"} target="_blank">
                <img src="resources/images/logos/ews.jpg" style={{width: '70px', marginRight: '40px'}}
                     alt="EWS"/>
            </a>
            <a href={"https://www.cer.be"} target="_blank">
                <img src="resources/images/logos/cer.png" style={{width: '70px', marginRight: '40px'}}
                     alt="CER"/>
            </a>
            <a href={"https://eimrail.org"} target="_blank">
                <img src="resources/images/logos/eim.jpg" style={{width: '100px', marginRight: '40px'}}
                     alt="EIM"/>
            </a>
            <a href={"https://uic.org"} target="_blank">
                <img src="resources/images/logos/uic.svg" style={{width: '60px', marginRight: '40px'}}
                     alt="UIC"/>
            </a>
            <a href={"https://staygrounded.org"} target="_blank">
                <img src="resources/images/logos/stay-grounded.png" style={{width: '120px', marginRight: '40px'}}
                     alt="UIC"/>
            </a>
            <a href={"https://strategiemakers.nl"} target="_blank">
                <img src="resources/images/logos/strategiemakers.png" style={{width: '120px', marginRight: '40px'}}
                     alt="UIC"/>
            </a>


            <a href={"https://infrabel.be"} target="_blank">
                <img src="resources/images/logos/infrabel_logo.png"
                     style={{width: '100px', marginRight: '40px', marginTop: '10px'}}
                     alt="Infrabel"/>
            </a>
            <a href={"https://www.belgiantrain.be"} target="_blank">
                <img src="resources/images/logos/SNCB.png"
                     style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                     alt="SNCB"/>
            </a>
            <a href={"https://www.getlinkgroup.com/en/"} target="_blank">
                <img src="resources/images/logos/getlink.png"
                     style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                     alt="Getlink"/>
            </a>
            <a href={"https://www.sncf-reseau.com/fr"} target="_blank">
                <img src="resources/images/logos/SNCF.png"
                     style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                     alt="SNCF Reseau"/>
            </a>
            <a href={"https://highspeed1.co.uk"} target="_blank">
                <img src="resources/images/logos/hs1.png"
                     style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                     alt="HS1"/>
            </a>


            <div style={{marginTop: '5px'}}>as part of the <a href="https://europa.eu/year-of-rail/index_en"
                                                              target="_blank">European Year of Rail 2021</a></div>
        </p>

        <Grid stackable>
            <Grid.Row>
                <Grid.Column width={10}>

                    <h2>At a glance</h2>
                    <p>Please check the info-wagon #3 for your personal travel information including your coach number and departure
                        station.</p>

                    <h3>Receiving updates</h3>
                    <p>Things are changing quickly, so better make sure to stay up-to-date. The best places to do so are:</p>
                    <ul>
                        <li>The RTTC Telegram-Channel: <a href="https://t.me/RailToTheCOP"
                                                          target="_blank">https://t.me/RailToTheCOP</a></li>
                        <li>Our Slack Channel: find the invite link in our info-wagons</li>
                    </ul>

                    <h3>UK travel requirements and Covid-19 restrictions</h3>
                    <p>Please check UK travel requirements on <a href="https://eurostar.com" target="_blank">eurostar.com</a> and on
                        the <a
                            href={"https://www.gov.uk/guidance/travel-to-england-from-another-country-during-coronavirus-covid-19"}
                            target="_blank">UK government website</a>. Note that COP 26 attendants who have UNFCCC accreditation
                        must respect a specific Covid code of conduct with stricter pre-travel requirements. Please check the UNFCCC
                        website.</p>

                    <h3>As a summary, please remember to:</h3>
                    <ol>
                        <li>Bring a valid passport. ID cards are no longer sufficient!</li>
                        <li>Within 48 hours before departure, complete the UK passenger locator form.</li>
                        <li><strong>If you are fully vaccinated</strong> according to UK standards, book and pay for your day 2
                            COVID-19 test before you depart for the UK. You should book an appointment via the <a
                                href={"https://quarantinehotelbookings.ctmportal.co.uk/"} target={"_blank"}>CTM booking portal</a>,
                            or <a href={"https://www.find-travel-test-provider.service.gov.uk/test-type/green"} target={"_blank"}>buy
                                a test from one of the private providers in England</a> (prices can range between £20 and £40).
                        </li>
                        <li>If you are not fully vaccinated, present a negative Antigen, LAMP or PCR test result that meets the
                            standards required by the UK government. The test must be taken a maximum of 3 days before your
                            departure date. You will also need to self-isolate for 10 days and take a COVID-19 PCR test - within 2
                            days of arrival and again on or after day 8.
                        </li>
                    </ol>

                </Grid.Column>
                <Grid.Column width={6}>

                    <h3>Timeline</h3>
                    <p>Please arrive 120 minutes before departure at your train station, since border crossing procedures will take
                        place in the stations. The gates will close 30 minutes before the train leaves.</p>

                    <div className="journey-part date">
                        <Icon name="calendar alternate"/>
                        {t("join.the-journey-date")}
                    </div>
                    <br/>
                    <div className="journey-part" color="blue">
                        Amsterdam
                        <span>08:47 CEST</span>
                    </div>
                    <br/>
                    <div className="journey-part" color="blue">
                        Rotterdam
                        <span>09:28 CEST</span>
                    </div>
                    <br/>
                    <div className="journey-part date" color="blue">
                        Brussels Welcome Event
                        <span>9:45 CEST</span>
                    </div>
                    <div className="journey-part " color="blue">
                        Brussels Departure
                        <span>10:48 CEST</span>
                    </div>
                    <br/>
                    <div className="journey-part" color="blue">
                        London (arrival St. Pancras)
                        <span>11:57 GMT</span>
                    </div>
                    <div className="journey-part" color="blue">
                        London (departure Euston)
                        <span>13:27 GMT</span>
                    </div>
                    <br/>
                    <div className="journey-part date" color="blue">
                        Glasgow arrival
                        <span>18:00 GMT</span>
                    </div>
                    <br/><br/>
                    <p className="note">
                        Note: These are the official departures times. Please disregard any previous message with deviating times.
                    </p>

                </Grid.Column>
            </Grid.Row>
        </Grid>



        <hr />

        <h2>Programme on the train</h2>
        <p>The on-board program was designed to spark meaningful dialogue between youth, civil society, industry and
            policy. The aim is to build cross-sector alliances that accelerate the transition towards fair and
            sustainable travel and make the connection with the struggle for climate justice at COP26. The on-board
            programme enroute from Amsterdam to London will feature workshops led by NGOs, industry, and policy, which
            will generate ideas, share knowledge, and build personal connections. Finally, the outcomes of the workshops
            will be synthesised and ready for printing on one-pagers when we arrive in Glasgow.</p>

        {/*<h2>Downloads</h2>*/}
        {/*Programme overview*/}
        {/*Coaches overview*/}
        {/*Step by step guide reimbursement https://docs.google.com/document/d/1Roam-Jql5GdvtOIes-xowIbmRYfr1eQXjaZlOc5Ziy0/edit#*/}

        {questions.map((qs, j) => <>
            <h3>{qs[0]}</h3>
            <Accordion fluid styled>
                {qs[1].map((q, i) => <div key={"q" + i + j * 100}>
                        <Accordion.Title
                            active={activeIndex === i + j * 100}
                            index={i + j * 100}
                            onClick={handleClick}
                            className={"faq-title"}
                        >
                            {q[0]}
                        </Accordion.Title>
                        <Accordion.Content
                            active={activeIndex === i + j * 100}
                            className={"faq-content"}
                        >
                            {q[1]}
                        </Accordion.Content>
                    </div>
                )}
            </Accordion><br/>
        </>)}

        <br/><br/>

        {/*<FormFAQ/>*/}

        <p>
            <Trans i18nKey="join.join-other-questions">
                Do you have any other question? Please don't hesitate to reach out to us at <a
                href="mailto:info@railtothecop.com">info@railtothecop.com</a>.
            </Trans>
        </p>
        <p>
            For press inquiries, e-mail <a href="mailto:press@railtothecop.com">press@railtothecop.com</a> or call
            +31614480371.
        </p>

    </Container>;
}

export default ParticipantSupport;