import React, {Component, useState} from "react";
import firebase from "firebase";
import FormIntro from "./intro";
import {Container, Grid, Loader, Modal, Table} from "semantic-ui-react";

const ApplicationsAdminPage = () => {

    const [loading, setLoading] = useState(false);
    const [user, setUser] = useState(firebase.auth().currentUser);
    const [applications, setApplications] = useState(false);
    const [viewApplication, setViewApplication] = useState(null);

    const [countries, setCountries] = useState({});
    const [genders, setGenders] = useState({});
    const [groups, setGroups] = useState({});
    const [tickets, setTickets] = useState(0);

    if (user) {
        if (!applications && !loading) {
            let db = firebase.firestore();
            setLoading(true);
            db.collection("applications").orderBy("createdAt")
                .get()
                .then(function (querySnapshot) {
                    let _applications = [];
                    let _countries = {};
                    let _groups = {};
                    let _genders = {};
                    let _tickets = 0;

                    querySnapshot.forEach((doc) => {
                        let application = doc.data();
                        application.id = doc.id;
                        _tickets += (application.numberOfTickets && parseInt(application.numberOfTickets)) || 0;

                        // console.log("tickets: " + application.numberOfTickets);

                        let country = (application.country || "").toLowerCase().trim()//.replace(/ /g, "")
                        if (!_countries[country]) _countries[country] = 0;
                        _countries[country]++;
                        let gender = (application.gender || "").toLowerCase().trim()//.replace(/ /g, "")
                        if (!_genders[gender]) _genders[gender] = 0;
                        _genders[gender]++;
                        let group = (application.group || "").toLowerCase().trim()//.replace(/ /g, "")
                        if (!_groups[group]) _groups[group] = 0;
                        _groups[group]++;

                        _applications.push(application);
                    });
                    setLoading(false);
                    setApplications(_applications);
                    setCountries(_countries);
                    setGenders(_genders);
                    setGroups(_groups);
                    setTickets(_tickets);

                    console.log(_applications.map(a => a.email).join(";"));

                    console.log(_countries);
                })
                .catch(function (error) {
                    console.error(error);
                    alert("Something went wrong, please let us know through info@railtothecop.com");
                });
        }
    } else {
        firebase.auth().signInWithEmailAndPassword("jeppebijker@gmail.com", prompt("password"))
            .then((userCredential) => {
                // Signed in
                var user = userCredential.user;
                console.log(user);
                setUser(user);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    return <>
        <h1>Applications Climate Train</h1>

        <Container className="adminPage">

            {applications && <h2>
                {applications.length} applications, {tickets} tickets
            </h2>}

            {loading && <Loader/>}

            {countries && <>

                <Grid>
                    <Grid.Row>
                        <Grid.Column width={5}>
                            <Table compact>
                                <Table.Body>
                                    {Object.keys(countries).map(a => <Table.Row key={"country" + a}>
                                        <Table.Cell>
                                            {a}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {countries[a]}
                                        </Table.Cell>
                                    </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Table compact>
                                <Table.Body>
                                    {Object.keys(genders).map(a => <Table.Row key={"gender" + a}>
                                        <Table.Cell>
                                            {a}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {genders[a]}
                                        </Table.Cell>
                                    </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column width={5}>
                            <Table compact>
                                <Table.Body>
                                    {Object.keys(groups).map(a => <Table.Row key={"gr" + a}>
                                        <Table.Cell>
                                            {a}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {groups[a]}
                                        </Table.Cell>
                                    </Table.Row>)}
                                </Table.Body>
                            </Table>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>

            </>}

            {applications && <Table>
                <Table.Header>

                    <Table.Row>
                        <Table.HeaderCell>
                            #
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Date
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            E-mail
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            Group
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                            # of tickets
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>
                    {applications.map((a, i) => <Table.Row key={a.id} onClick={() => setViewApplication(a)}>
                        <Table.Cell>
                            {i+2}
                        </Table.Cell>
                        <Table.Cell>
                            {a.createdAt.toDate().toString()}
                        </Table.Cell>
                        <Table.Cell>
                            {a.email}
                        </Table.Cell>
                        <Table.Cell>
                            {a.group}
                        </Table.Cell>
                        <Table.Cell>
                            {a.numberOfTickets}
                        </Table.Cell>
                    </Table.Row>)}
                </Table.Body>
            </Table>}

            {viewApplication && <Modal
                open={viewApplication}
                onClose={() => setViewApplication(null)}
            >
                <Modal.Header>{viewApplication.email}</Modal.Header>
                <Modal.Content>

                    <Table compact>
                        <Table.Body>
                            <tr>
                                <td>ID</td>
                                <td>{viewApplication.id}</td>
                            </tr>
                            {Object.keys(viewApplication).map(key => <tr key={"m" + key}>
                                <td>
                                    {key}
                                </td>
                                <td>
                                    {JSON.stringify(viewApplication[key])}
                                </td>
                            </tr>)}

                        </Table.Body>
                    </Table>


                </Modal.Content>
            </Modal>}

        </Container>

    </>;

}


export default ApplicationsAdminPage;