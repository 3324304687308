import React, {Component} from 'react';
import {Button, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";
import Team from "../team/team";

class TeamPage extends Component {

    render() {

        return (
            <div className="page-container home">

                <Container>
                    <Grid stackable className="ui grid stackable">

                        <Grid.Row>

                            <Grid.Column width={16}>
                                <h1>
                                    The Rail to the COP team
                                </h1>

                                <Team />

                            </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h2>

                                </h2>
                                <p>
                                </p>

                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                </Container>

            </div>
        );
    }
}

export default TeamPage;
