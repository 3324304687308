import React, {Component} from 'react';
import {NavLink, Route, BrowserRouter as Router, withRouter, Switch} from "react-router-dom";
import Home from "./pages/home";

import About from "./pages/about";
import Team from "./pages/about/team";
import Organization from "./pages/about/organization";
import Mission from "./pages/about/mission";
import Contact from "./pages/contact";
import FormPage from "./pages/form";
import ApplicationsAdminPage from "./pages/form/admin";
import PrivacyPolicy from "./pages/privacy-policy";
import ParticipantSupport from "./pages/participant-support";

class ScrollToTopComponent extends Component {
    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            window.scrollTo(0, 0)
        }
    }

    render() {
        return this.props.children
    }
}

const ScrollToTop = withRouter(ScrollToTopComponent);

class App extends React.Component {

    render() {

        return (
            <ScrollToTop>

                <div className="menu-container">
                    <div className="logo">
                        <NavLink to={"/"}>
                            <img src="resources/images/railtothecop-logo-white.png"
                                 alt="Rail to the COP"/>
                        </NavLink>
                    </div>


                    <div
                        className={"tablet or lower hidden crowdfunding-button"}
                        id="crowdfunding-button">

                        <a href="https://opencollective.com/rail-to-the-cop"
                           target="_blank"
                           className={"ui button primary"}>Donate</a>

                    </div>

                    <ul className="menu actions">
                        <li>
                            <NavLink to="/" exact>Rail to the COP</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about/mission">About us</NavLink>
                        </li>
                        <li>
                            <NavLink to="/contact">Contact</NavLink>
                        </li>
                        <li>
                            <NavLink to="/climate-train">The Climate Train</NavLink>
                        </li>
                    </ul>

                    <Route path="/about" component={() => <ul className="menu topics">
                        <li>
                            <NavLink to="/about/mission">Our mission</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about/organization">Organization</NavLink>
                        </li>
                        <li>
                            <NavLink to="/about/team">The team</NavLink>
                        </li>
                    </ul>}/>

                </div>

                <div id="content-container">
                    <Switch>

                        <Route path="/" exact component={Home}/>
                        <Route path="/about" exact component={About}/>
                        <Route path="/about/mission" exact component={Mission}/>
                        <Route path="/about/organization" exact component={Organization}/>
                        <Route path="/about/team" exact component={Team}/>
                        <Route path="/contact" exact component={Contact}/>
                        <Route path="/form" exact component={FormPage}/>
                        <Route path="/apply" exact component={FormPage}/>
                        <Route path="/climate-train" exact component={ParticipantSupport}/>
                        <Route path="/admin" exact component={ApplicationsAdminPage}/>
                        <Route path="/privacy-policy" exact component={PrivacyPolicy}/>

                    </Switch>
                </div>


                <div className="ui inverted vertical footer">
                    <div className="ui container">
                        <div className="ui stackable inverted divided equal height stackable grid">
                            <div className="row">

                                {/*<div className="four wide column">*/}
                                {/*    <ul className="menu topics">*/}
                                {/*        <li className="part">*/}
                                {/*            <NavLink to="/blog">Blog</NavLink>*/}
                                {/*        </li>*/}
                                {/*    </ul>*/}
                                {/*</div>*/}
                            </div>
                        </div>

                        <div className="ui stackable inverted equal height stackable grid">
                            <div className="row">
                                <div className="eleven wide column">
                                    <strong>Rail to the COP</strong> promotes and facilitates the transition towards a
                                    sustainable travel future.
                                    Building upon <a href="http://www.sailtothecop.com">Sail to the COP</a>.

                                    <br/>
                                    <br/>

                                    <a className="ui labeled icon button facebook"
                                       href="https://www.facebook.com/pg/railtothecop"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <i className='ui icon facebook'/> Follow us on Facebook
                                    </a>{" "}
                                    <a className="ui labeled icon button instagram"
                                       href="https://www.instagram.com/railtothecop/"
                                       target="_blank"
                                       rel="noopener noreferrer">
                                        <i className='ui icon instagram'/> Follow us on Instagram
                                    </a><br/><br/>

                                </div>
                                <div className="five wide column right aligned">


                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </ScrollToTop>
        );
    }
}

export default App;
