import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './resources/responsive.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';
import firebase from 'firebase/app';
import 'firebase/database';
import {I18nextProvider} from "react-i18next";
import i18n from "./i18n";
import LanguageProvider from "./providers/LanguageProvider";
import {BrowserRouter as Router} from "react-router-dom";

// Initialize Firebase
const config = {
    apiKey: "AIzaSyC5-BOvj8JWSYI5pZdUW-DZ1ZjgnNhX34I",
    authDomain: "rail-to-the-cop.firebaseapp.com",
    databaseURL: "https://rail-to-the-cop-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "rail-to-the-cop",
    storageBucket: "rail-to-the-cop.appspot.com",
    messagingSenderId: "545471098549",
    appId: "1:545471098549:web:063401f92354e855e718fc"
};
firebase.initializeApp(config);

// window.db = firebase.firestore();
// window.db.settings({
//     timestampsInSnapshots: true
// });


ReactDOM.render(
    <Router>
        <I18nextProvider i18n={i18n}>
            <LanguageProvider>
                <App/>
            </LanguageProvider>
        </I18nextProvider>
    </Router>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
