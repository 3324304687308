import {Accordion, Container, Grid, Icon, Label} from "semantic-ui-react";
import React from "react";
import {useTranslation} from "react-i18next";

const FormIntro = () => {

    const { t } = useTranslation();

    return <Grid stackable>
        <Grid.Row>
            <Grid.Column width={11}>

                <h3>{t("join.the-climate-train-heading")}</h3>

                {/*<blockquote>*/}
                {/*    Note: Sign ups have not yet opened, watch our social channels to receive updates on the launch.*/}
                {/*</blockquote>*/}

                <p>
                    {t("join.the-climate-train-copy")}
                </p>
                <p style={{textAlign: 'center'}}>
                    <a href={"https://www.prorail.nl"} target="_blank">
                        <img src="resources/images/logos/prorail.svg" style={{width: '170px', marginRight: '40px'}}
                             alt="ProRail"/>
                    </a>
                    <a href={"https://www.eurostar.com"} target="_blank">
                        <img src="resources/images/logos/eurostar.png" style={{width: '160px', marginRight: '40px'}}
                             alt="ProRail"/>
                    </a>
                    <a href={"https://www.ns.nl"} target="_blank">
                        <img src="resources/images/logos/ns.svg" style={{width: '80px', marginRight: '40px'}}
                             alt="NS"/>
                    </a>
                    <a href={"https://avantiwestcoast.co.uk"} target="_blank">
                        <img src="resources/images/logos/avanti.png" style={{width: '150px', marginRight: '40px'}}
                             alt="Avanti"/>
                    </a>
                </p><br />
                <p style={{textAlign: 'center'}}>
                    <div style={{marginBottom: '5px'}}><strong>Supported by</strong></div>
                    <a href={"http://ews-schoenau.de"} target="_blank">
                        <img src="resources/images/logos/ews.jpg" style={{width: '70px', marginRight: '40px'}}
                             alt="EWS"/>
                    </a>
                    <a href={"https://www.cer.be"} target="_blank">
                        <img src="resources/images/logos/cer.png" style={{width: '70px', marginRight: '40px'}}
                             alt="CER"/>
                    </a>
                    <a href={"https://eimrail.org"} target="_blank">
                        <img src="resources/images/logos/eim.jpg" style={{width: '100px', marginRight: '40px'}}
                             alt="EIM"/>
                    </a>
                    <a href={"https://uic.org"} target="_blank">
                        <img src="resources/images/logos/uic.svg" style={{width: '60px', marginRight: '40px'}}
                             alt="UIC"/>
                    </a>
                    <a href={"https://staygrounded.org"} target="_blank">
                        <img src="resources/images/logos/stay-grounded.png" style={{width: '120px', marginRight: '40px'}}
                             alt="UIC"/>
                    </a>
                    <a href={"https://strategiemakers.nl"} target="_blank">
                        <img src="resources/images/logos/strategiemakers.png" style={{width: '120px', marginRight: '40px'}}
                             alt="UIC"/>
                    </a>


                    <a href={"https://infrabel.be"} target="_blank">
                        <img src="resources/images/logos/infrabel_logo.png" style={{width: '100px', marginRight: '40px', marginTop: '10px'}}
                             alt="Infrabel"/>
                    </a>
                    <a href={"https://www.belgiantrain.be"} target="_blank">
                        <img src="resources/images/logos/SNCB.png" style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                             alt="SNCB"/>
                    </a>
                    <a href={"https://www.getlinkgroup.com/en/"} target="_blank">
                        <img src="resources/images/logos/getlink.png" style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                             alt="Getlink"/>
                    </a>
                    <a href={"https://www.sncf-reseau.com/fr"} target="_blank">
                        <img src="resources/images/logos/SNCF.png" style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                             alt="SNCF Reseau"/>
                    </a>
                    <a href={"https://highspeed1.co.uk"} target="_blank">
                        <img src="resources/images/logos/hs1.png" style={{width: '50px', marginRight: '40px', marginTop: '10px'}}
                             alt="HS1"/>
                    </a>


                    <div style={{marginTop: '5px'}}>as part of the <a href="https://europa.eu/year-of-rail/index_en" target="_blank">European Year of Rail 2021</a></div>
                </p>

                {/*<h2>{t("join.apply-heading")}</h2>*/}
                {/*<p className={"pre-wrap"}>*/}
                {/*    {t("join.apply-copy")}*/}
                {/*</p>*/}



                {/*<br />*/}

                {/*<h3>{t("join.booking-process-heading")}</h3>*/}
                {/*<ol>*/}
                {/*    <li>{t("join.booking-process-step1")}</li>*/}
                {/*    <li>{t("join.booking-process-step2")}</li>*/}
                {/*</ol>*/}

            </Grid.Column>
            <Grid.Column width={5}>

                <h3>{t("join.the-journey-heading")}</h3>
                <div className="journey-part date">
                    <Icon name="calendar alternate"/>
                    {t("join.the-journey-date")}
                </div>
                <br/>
                <div className="journey-part" color="blue">
                    Amsterdam
                    <span>08:47 CEST</span>
                </div>
                <br/>
                <div className="journey-part" color="blue">
                    Rotterdam
                    <span>09:28 CEST</span>
                </div>
                <br/>
                <div className="journey-part" color="blue">
                    Brussels
                    <span>10:48 CEST</span>
                </div>
                <br/>
                <div className="journey-part" color="blue">
                    London
                    <span>11:57 GMT</span>
                </div>
                <br/>
                <div className="journey-part" color="blue">
                    Glasgow
                    <span>{t("join.the-journey-arrival-glasgow")}</span>
                </div>
                <br/><br/>
                <p className="note">
                    {t("join.the-journey-note")}
                </p>

                <h3>{t("join.fare-heading")}</h3>

                <Label size="large">€70 <small>{t("join.fare-standard-class")}</small></Label> / <Label size="large">€120 <small>{t("join.fare-business-class")}</small></Label>
                <br/><br />

                <p>
                    {t("join.fare-copy")}
                </p>

            </Grid.Column>
        </Grid.Row>
    </Grid>;
}

export default FormIntro;