import React, {Component} from 'react';
import {Button, Card, CardContent, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";
import Team from "./team/team";

class Home extends Component {

    render() {

        return (
            <div className="page-container home">

                <Container>
                    <Grid stackable verticalAlign="bottom">

                        <Grid.Row centered>

                            <Grid.Column width={8}>
                                <img src="/resources/images/Train on bridge.jpg"/>
                            </Grid.Column>

                            <Grid.Column width={5}>

                                <p className="big-quote">
                                    We imagine a world, in which travelling is always meaningful and respects planetary
                                    boundaries.
                                </p>
                                <p className="big-quote">
                                    We dream of a reality in which all participatory processes lead to bold
                                    action, fostering a fair and sustainable travel industry.
                                </p>
                                <p className="big-quote">
                                    We envision a future in
                                    which travel is a force for good and does no harm to the travellers themselves and
                                    the ecosystems and communities that welcome them.
                                </p>
                                <br/><br/>

                                <Link to={"/apply"}><Button primary>Apply for The Climate Train</Button></Link>

                                <br/><br/>
                            </Grid.Column>
                        </Grid.Row>

                    </Grid>
                    <Grid stackable>

                        <Grid.Row>

                            <Grid.Column width={10}>
                                <br />
                                <div className="vertical-video">
                                    <iframe
                                        src={`https://www.youtube-nocookie.com/embed/8toG1swjEoY`}
                                        frameBorder="0"
                                    />
                                </div>
                                {/*<iframe width="315" height="560"*/}
                                {/*        src="https://www.youtube-nocookie.com/embed/esGw0TUl-Wc"*/}
                                {/*        title="YouTube video player" frameBorder="0"*/}
                                {/*        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"*/}
                                {/*        allowFullScreen />*/}
                            </Grid.Column>

                            <Grid.Column width={6}>
                                <h2 style={{textAlign: "center"}}>
                                    Our vision
                                </h2>
                                {/*<Grid stackable>*/}
                                {/*    <Grid.Row centered>*/}
                                {/*        <Grid.Column width={5} >*/}
                                            <p>
                                                <span className="emphasis">Governments and institutions follow the scientific evidence and
                                                facilitate a
                                                    global system change.</span> Restrictive regulations on polluting
                                                travel and
                                                ambitious
                                                carbon goals are implemented and financial instruments and incentives
                                                are
                                                introduced leading to a dominantly low-impact sector with fair travel
                                                practices
                                                that are affordable for all.
                                            </p>
                                        {/*</Grid.Column>*/}
                                        {/*<Grid.Column width={5}>*/}
                                            <p>
                                                <span className="emphasis">Transport companies and service providers pay for the polluting damages</span>
                                                {" "}(of
                                                the
                                                past), fairly distribute their profits among their employees and ensure
                                                that
                                                their strategy includes the interests of local communities in popular
                                                travel
                                                destinations. Additionally, the necessary information for booking and
                                                planning
                                                sustainable trips is made accessible to the public, driving an increased
                                                demand
                                                for future-proof travelling.
                                            </p>
                                        {/*</Grid.Column>*/}
                                        {/*<Grid.Column width={5}>*/}
                                            <p>
                                                <span className="emphasis">People choose ways that enable ecosystems to heal, cultures to flourish
                                                and
                                                    employees of the travel industry to be treated fairly.</span> Based
                                                on these
                                                new
                                                standards, future generations can still have the chance to experience
                                                the
                                                wonders of nature and our societies.
                                            </p>
                                {/*        </Grid.Column>*/}
                                {/*    </Grid.Row>*/}
                                {/*</Grid>*/}
                            </Grid.Column>

                        </Grid.Row>

                        <Grid.Row>

                            <Grid.Column width={16}>

                                <br/><br/>
                                <br/><br/>

                                <h1>
                                    Rail to the COP
                                </h1>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={10}>

                                <p>
                                    The Rail to the COP campaign promotes and facilitates the transition towards a
                                    sustainable travel future. {/*As its flagship initiative, Rail to the COP organises a
                                    Climate Train from Amsterdam to Glasgow, to connect and mobilize people on their way
                                    towards the international climate conference: COP26.*/} The campaign facilitates
                                    dialogue between the rail industry, climate activists, youth and policy makers.{/*}
                                    onboard the Climate Train through Amsterdam, Brussels, London to Glasgow, as well as
                                    in events before, after and at the COP.*/} The campaign further encourages and
                                    supports
                                    other sustainable travel initiatives towards
                                    Amsterdam, {/*} and the Climate Train, and*/}
                                    Glasgow and COP26.
                                </p>
                                <blockquote>
                                    Rail to the COP amplifies and combines the diverse voices of its participants{/*, from
                                    the Climate Train and beyond,*/} to accelerate the transition toward a sustainable
                                    and
                                    just travel industry!

                                </blockquote>

                            </Grid.Column>
                            <Grid.Column width={6}>

                                <img src="/resources/images/train-2.jpg"/>
                            </Grid.Column>
                        </Grid.Row>
                        <Grid.Row>
                            <Grid.Column width={16}>

                                <br/><br/>

                                <h2>
                                    Who are we?
                                </h2>

                                <p>
                                    We are Rail to the COP, a campaign by Youth for Sustainable Travel (YFST). YFST is a
                                    European non-profit aiming to promote sustainable travel and raising awareness about
                                    issues in the travel industry. The organization is registered and based in the
                                    Netherlands. In 2019 Youth for Sustainable Travel organized their first campaign,
                                    Sail to the COP.
                                </p>
                                <p>
                                    The organizing team of Rail to the COP consists of Sail to the COP participants and
                                    young climate activists and experts. {/*We teamed up with the Dutch rail infrastructure
                                    manager ProRail to organize the Climate Train to COP26 in Glasgow.*/}

                                </p>

                                <br/>

                                <Team summary/>

                                <br style={{clear: "both"}}/><br/>

                            </Grid.Column>

                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column width={8} textAlign="center">
                                <div className="card pink">
                                    <div className="padding">
                                        <h2>
                                            Get involved
                                        </h2>
                                        <p>
                                            Want to stay up to date? Subscribe to our newsletter below. We will send you
                                            a
                                            monthly newsletter to inform you about our campaign.
                                        </p>
                                        <div id="mc_embed_signup">
                                            <Form
                                                action="https://railtothecop.us4.list-manage.com/subscribe/post?u=2f57303ba0864c71ddbfa2faf&amp;id=86d3818282"
                                                method="post" id="mc-embedded-subscribe-form"
                                                name="mc-embedded-subscribe-form"
                                                class="validate" target="_blank" novalidate>
                                                <div id="mc_embed_signup_scroll">

                                                    <div className="mc-field-group">
                                                        <Input type="email" name="EMAIL" className="required email"
                                                               id="mce-EMAIL" placeholder="E-mail address"
                                                               style={{minWidth: '300px', marginRight: '10px'}}/>
                                                        <Button type="submit" value="Subscribe" name="subscribe"
                                                                id="mc-embedded-subscribe"
                                                                className="button">Subscribe</Button>
                                                    </div>
                                                    <div id="mce-responses" className="clear">
                                                        <div className="response" id="mce-error-response"
                                                             style={{display: "none"}}/>
                                                        <div className="response" id="mce-success-response"
                                                             style={{display: "none"}}/>
                                                    </div>
                                                    <div style={{position: "absolute", left: "-5000px"}}
                                                         aria-hidden="true">
                                                        <input type="text" name="b_2f57303ba0864c71ddbfa2faf_86d3818282"
                                                               tabIndex="-1" value=""/></div>
                                                    <div className="clear">
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <br/>
                                        <p>
                                            Or follow our social media channels for more regular content:
                                        </p>
                                        <p>
                                            <a className="ui labeled icon button facebook"
                                               href="https://www.facebook.com/pg/railtothecop"
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                <i className='ui icon facebook'/> Follow us on Facebook
                                            </a>{" "}
                                            <a className="ui labeled icon button instagram"
                                               href="https://www.instagram.com/railtothecop/"
                                               target="_blank"
                                               rel="noopener noreferrer">
                                                <i className='ui icon instagram'/> Follow us on Instagram
                                            </a><br/><br/>
                                        </p>
                                    </div>
                                </div>
                            </Grid.Column>

                            <Grid.Column width={8} textAlign="center">
                                <div className="card">
                                    <div className="padding">
                                        <h2>
                                            Reach out
                                        </h2>
                                        <p>
                                            Are you interested in joining our team, or are you interested in organizing
                                            your own
                                            group travelling to{" "}
                                            {/*the Climate Train to */}
                                            Glasgow &mdash; get in touch
                                            through <a
                                            href="mailto:info@railtothecop.com">info@railtothecop.com</a>.

                                        </p>
                                    </div>
                                </div>
                            </Grid.Column>

                        </Grid.Row>


                        <Grid.Row className="media-container">

                        </Grid.Row>

                    </Grid>
                </Container>

            </div>
        );
    }
}

export default Home;
