import React, {Component} from 'react';
import {Button, Container, Form, Grid, Image, Input, Message} from "semantic-ui-react";
import {Link} from "react-router-dom";

class Organization extends Component {


    render() {

        return (
            <div className="page-container home">

                <Container>
                    <Grid stackable className="ui grid stackable">

                        <Grid.Row>

                            <Grid.Column width={16}>
                                <h1>
                                    Who are we?
                                </h1>
                                <p>
                                    We are Rail to the COP, a campaign by <strong>Youth for Sustainable Travel</strong> (YfST). YfST is a
                                    European non-profit aiming to promote sustainable travel and raising awareness about
                                    issues in the travel industry. The organization is registered and based in the
                                    Netherlands. In 2019 Youth for Sustainable Travel organized their first campaign,
                                    Sail to the COP.
                                </p>
                                <p>
                                    The organizing team of Rail to the COP consists of Sail to the COP participants and
                                    young climate activists and experts.
                                    {/*We teamed up with the Dutch rail infrastructure*/}
                                    {/*manager ProRail to organize the Climate Train to COP26 in Glasgow.*/}
                                </p>
                                <h2>
                                    Youth for Sustainable Travel
                                </h2>
                                <p>
                                    Youth for Sustainable Travel is a European non-profit with the mission of building
                                    and strengthening an international youth movement that campaigns for a just
                                    transition to a sustainable travel future. We’re representing a generation of which
                                    many have access to cheap faraway travel as well as a generation that is the last to
                                    take action!
                                </p>
                                <p>
                                    Our serious worries about the climate crisis, the emissions and injustices in travel
                                    specifically, are translated into campaigns that make a real change.

                                </p>

                            </Grid.Column>
                        </Grid.Row>


                        <Grid.Row>
                            <Grid.Column width={16}>
                                <h2>
                                    Sail to the COP
                                </h2>
                                <p>
                                    Sail to the COP was the first project by Youth for Sustainable Travel. The campaign and sailing think tank set sail to UNFCCCs conference COP25 in Chile in 2019.
                                    </p>
                                <p>
                                    <a href="https://www.sailtothecop.com" target="_blank">Read more on sailtothecop.com</a>
                                </p>

                                <br /><br />

                                <img src="/resources/images/ship-small.jpg" />

                            </Grid.Column>
                        </Grid.Row>

                        {/*<Grid.Row>*/}
                        {/*    <Grid.Column width={16}>*/}
                        {/*        <h3>*/}
                        {/*            Partnership with ProRail*/}
                        {/*        </h3>*/}
                        {/*        <p>*/}
                        {/*            The Climate Train, which is a vital part of the Rail to the COP campaign is organized in partnership with ProRail, the Dutch railway infrastructure manager. In collaboration with ProRail we both organize the train itself and the program onboard of the train, consisting of workshops, lectures and dialogue sessions.*/}
                        {/*        </p>*/}
                        {/*        <p>*/}
                        {/*            <a href="https://www.prorail.nl" target="_blank">Read more about ProRail</a>*/}
                        {/*        </p>*/}

                        {/*    </Grid.Column>*/}
                        {/*</Grid.Row>*/}

                    </Grid>
                </Container>

            </div>
        );
    }
}

export default Organization;
