import {Accordion, Grid, Icon} from "semantic-ui-react";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
import {useLanguage} from "../../providers/LanguageProvider";
import {languages} from "../../i18n";

const questions = {
    'en': [
        [
            <>Tickets & Cancellation</>,
            [
                [
                    <>
                        I want to travel to COP on another date, is that possible?
                    </>,
                    <p>
                        We had to choose a date to run the Climate Train but realize that some people might want to
                        travel
                        to COP earlier on later. Of course we'd still encourage you to travel by train, and you can
                        easily book the necessary tickets:<br/><br/>

                        You can buy a ticket with <a href={"https://www.eurostar.com"} target="_blank">Eurostar</a> to
                        get to London.
                        In the UK, the West Coast line from London to
                        Glasgow is operated by <a href={"https://avantiwestcoast.co.uk"} target="_blank">Avanti</a>,
                        and the East Coast line to Edinburgh is operated by <a href={"https://www.lner.co.uk"}
                                                                               target={"_blank"}>LNER</a>. There is
                        also a nighttrain from London to Glasgow: the <a href={"https://www.sleeper.scot"}
                                                                         target="_blank">Caledonian Sleeper</a>.

                        <br/><br/>

                        Alternatives would be a bus (for example <a href={"https://global.flixbus.com"}
                                                                    target={"_blank"}>Flixbus</a>) or a ferry
                        (for example <a href={"https://www.dfds.com/en"} target={"_blank"}>DFDS</a> from IJmuiden to
                        Newcastle).
                    </p>
                ],
                [
                    <>
                        How much do the tickets cost?
                    </>,
                    <p>
                        The regular prices for the journey from Amsterdam, Rotterdam or Brussels to Glasgow are €70 in
                        standard
                        class and €120 in business class. You will also be able to book a return ticket from London to
                        Brussels,
                        Rotterdam or Amsterdam at a discount rate, but you will have to book your journey from Glasgow
                        to
                        London
                        by yourself.
                    </p>
                ],
                [
                    <>
                        How flexible is my ticket?
                    </>,
                    <p>
                        Eurostar will fully reimburse your ticket if you decide to cancel your journey up to 7 days
                        before
                        travel. Additionally, your return ticket from London to Brussels, Rotterdam or Amsterdam will be
                        exchangeable up to 24 hours before departure
                    </p>
                ],
                [
                    <>
                        Can I buy multiple tickets for my organisation?
                    </>,
                    <p>
                        Please give us an indication how many people from your organisation would like to be part of the
                        Climate
                        Train. We want to amplify as many voices as possible. Hence, we can not give 10 tickets to one
                        organisation. We will get back to you to see how many tickets you will be able to receive.
                    </p>
                ],
                [
                    <>
                        What happens if COP26 does not take place due to the pandemic?
                    </>,
                    <p>
                        We really hope that COP26 will happen as it is planned, but we are of course aware that that
                        might
                        not
                        happen. If we have to cancel the Climate Train we will reimburse the money.
                    </p>
                ],
            ],
        ],
        [
            <>The Climate Train & Rail to the COP</>,
            [

                [
                    <>
                        What exactly is going to happen on the train?
                    </>,
                    <p>
                        Short answer: a lot! We want to organise discussions, exchange experiences, finalize our
                        demands
                        for
                        COP26 and much more.
                    </p>
                ],
                [
                    <>
                        Can I join the train but not participate in the programme?
                    </>,
                    <p>
                        Our vision for this train is to come together, be able to have conversations with policy
                        makers.
                        Hence,
                        we warmly invite everyone to participate in the programme.
                    </p>
                ],

                [
                    <>
                        Who will be on the train?
                    </>,
                    <p>
                        We invited politicians and civil servants, railway industry and civil society representatives
                        and loads of (youth) activists to create a platform for an exchange between all parts of the
                        society. We
                        will have at least 300 seats for representatives from the civil society on the train.
                    </p>
                ],
                [
                    <>
                        I don’t have any experience with climate action or a UN conference. Can I still join?
                    </>,
                    <p>
                        Of course. We want to be as inclusive as possible and invite everyone to participate in this
                        journey,
                        get to know other activists, politicians and work together on solutions for the climate
                        crisis.
                        And
                        we
                        need everyone, every voice and every story to create a better future. You are warmly invited
                        to
                        join
                        the
                        Climate Train.
                    </p>
                ],
                [
                    <>
                        Is accommodation included in the ticket for the Climate Train?
                    </>,
                    <p>
                        Unfortunately we can not offer accommodation for every participant at this stage. You can
                        book a
                        room
                        via our partner Human Hotel <a href={"https://www.humanhotel.com/cop26/be-a-guest/"}
                                                       target={"_blank"}>humanhotel.com/cop26/be-a-guest</a>
                    </p>
                ],
                [
                    <>
                        Can I enter COP with the Climate Train ticket?
                    </>,
                    <p>
                        No, to be able to enter the so-called Blue Zone at COP26 venue you must be accredited by the
                        UNFCCC
                        secretary. We will not receive any accreditations ourselves. But there are amazing
                        possibilities
                        to
                        be
                        active outside of the COP26 venue. The international civil society will organise actions,
                        creative
                        spaces and other activities. So, feel free to join the Climate Train, even without being
                        accredited
                        to
                        COP26.
                    </p>
                ],

                [
                    <>
                        I am going to COP but not joining the Climate Train. Where can I talk to Rail to the COP at
                        the
                        event in
                        Glasgow?
                    </>,
                    <p>
                        We currently work on events at COP26 in Glasgow. At this stage we can not say exactly where
                        we
                        will
                        be.
                        If you would like to collaborate on events or this project, please contact us at
                        <a href="mailto:info@railtothecop.com">info@railtothecop.com</a>.
                    </p>
                ],
            ],
        ],
        [
            <>Travelling</>,
            [
                [
                    <>
                        What if I did not get a ticket? Where should I best buy an alternative?
                    </>,
                    <p>
                        You can buy a ticket with <a href={"https://www.eurostar.com"} target="_blank">Eurostar</a> to
                        get to London.
                        In the UK, the West Coast line from London to
                        Glasgow is operated by <a href={"https://avantiwestcoast.co.uk"} target="_blank">Avanti</a>,
                        and the East Coast line to Edinburgh is operated by <a href={"https://www.lner.co.uk"}
                                                                               target={"_blank"}>LNER</a>. There is
                        also a nighttrain from London to Glasgow: the <a href={"https://www.sleeper.scot"}
                                                                         target="_blank">Caledonian Sleeper</a>.

                        <br/><br/>

                        Alternatives would be a bus (for example <a href={"https://global.flixbus.com"}
                                                                    target={"_blank"}>Flixbus</a>) or a ferry
                        (for example <a href={"https://www.dfds.com/en"} target={"_blank"}>DFDS</a> from IJmuiden to
                        Newcastle).
                    </p>
                ],
                [
                    <>
                        It is not possible to take the train to the COP from outside of Europe. Are you expecting
                        non-Europeans
                        to only attend remotely?
                    </>,
                    <p>
                        Definitely not, we encourage everyone to join COP26. Unfortunately, it was only possible to
                        organise
                        a
                        train within Europe. Yet, we warmly welcome everyone on the train. So if you come from outside
                        Europe,
                        you could come to Amsterdam or Brussels (see “If I fly to Amsterdam or Brussels, am I still
                        welcome
                        on
                        the train?”) and join our train there.
                    </p>
                ],
                [
                    <>
                        How can I get to Amsterdam/Rotterdam/Brussels?
                    </>,
                    <p>
                        The Climate Train will bring you from Amsterdam/Rotterdam/Brussels to Glasgow. We are currently
                        working
                        on ways to include people from all over Europe by bringing them to Brussels via busses or
                        trains. At
                        this stage you have to book your own ticket to the city where you want to start. Do you want to
                        help
                        out
                        and organise your own bus/train to Brussels: reach out to us via <a
                        href="mailto:info@railtothecop.com">info@railtothecop.com</a>.
                    </p>
                ],

                [
                    <>
                        I have to travel to Brussels. Do you organize accommodation there?
                    </>,
                    <p>
                        No, we are not able to organize accommodation in Brussels.
                    </p>
                ],
                [
                    <>
                        If I fly to Amsterdam or Brussels, am I still welcome on the train?
                    </>,
                    <p>
                        Yes! We are aware that at this stage, the option to not fly to COP (especially from outside
                        Europe)
                        is a
                        huge privilege. We ask all European participants to travel by train or bus. Participants
                        from
                        outside
                        Europe are warmly welcomed on the train.
                    </p>
                ],
            ],
        ],
        [
            <>Sponsorship</>,
            [
                [
                    <>
                        I can’t afford a ticket, can my ticket be sponsored by Rail to the COP?
                    </>,
                    <p>
                        Everyone who cannot afford to pay the full train ticket to go to COP26 with the Climate
                        Train can ask to get their ticket sponsored.
                        We
                        want
                        to make the reimbursement as easy as possible. Unfortunately you have to pay the ticket in
                        the
                        first
                        place – due to technical reasons. We are working with OpenCollective to be able to reimburse
                        the
                        money
                        as soon as possible.
                    </p>
                ],
                [
                    <>
                        I can not pay the ticket in advance. How can I still join the Climate Train?
                    </>,
                    <p>
                        We are aware that paying € 70 can be a barrier for people to join. If you cannot advance the
                        money,
                        please reach out to us and we will find a way for you to book the ticket.
                    </p>
                ],
                [
                    <>
                        How can my ticket be sponsored?
                    </>,
                    <p>
                        Unfortunately it is not possible to pay a lower price during the booking process. Hence, you
                        have to
                        pay
                        the ticket in the first place. We partnered with Open Collective to be able to reimburse
                        (parts
                        of)
                        the
                        ticket price.
                    </p>
                ],
                [
                    <>
                        Is there a possibility to fund my trip to Brussels to join the Climate Train there?
                    </>,
                    <p>
                        Yes, we are currently working on ways to lower the price / fund the whole ticket. Please
                        give us
                        an
                        indication how much you will be able to pay for the ticket. This will not affect the success
                        of
                        your
                        application.
                    </p>
                ],
            ],
        ],
    ],
    'es': [

        [
            <>Billetes y cancelación</>,
            [
                [
                    <>
                        Quiero viajar a la COP en otra fecha, ¿es posible?
                    </>,
                    <p>
                        Tuvimos que elegir una fecha para poner en marcha el Tren del Clima, pero somos conscientes de
                        que algunas personas podrían querer viajar a la COP antes o después. Por supuesto, seguimos
                        animándole a viajar en tren, y puede reservar fácilmente los billetes necesarios:
                        <br/><br/>
                        Puedes comprar un billete con Eurostar para llegar a Londres. En el Reino Unido, la línea de la
                        Costa Oeste de Londres a Glasgow está operada por Avanti, y la de la Costa Este a Edimburgo por
                        LNER. También hay un tren nocturno de Londres a Glasgow: el Caledonian Sleeper.
                        <br/><br/>
                        Las alternativas serían un autobús (por ejemplo Flixbus) o un ferry (por ejemplo DFDS desde
                        IJmuiden a Newcastle).
                    </p>
                ],
                [
                    <>
                        ¿Cuánto cuestan los billetes?
                    </>,
                    <p>
                        Los precios regulares para el viaje de Ámsterdam, Rotterdam o Bruselas a Glasgow son de 70 euros
                        en clase estándar y 120 euros en clase business. También podrá reservar un billete de ida y
                        vuelta de Londres a Bruselas, Róterdam o Ámsterdam con una tarifa reducida, pero tendrá que
                        reservar su viaje de Glasgow a Londres por su cuenta. </p>
                ],
                [
                    <>
                        ¿Qué tan flexible es mi billete?
                    </>,
                    <p>
                        Eurostar le reembolsará íntegramente su billete si decide cancelar su viaje hasta 7 días antes
                        del mismo. Además, su billete de vuelta de Londres a Bruselas, Rotterdam o Ámsterdam podrá
                        cambiarse hasta 24 horas antes de la salida </p>
                ],
                [
                    <>
                        ¿Puedo comprar varios billetes para mi organización?
                    </>,
                    <p>
                        Indíquenos cuántas personas de su organización desean formar parte del Tren del Clima. Queremos
                        amplificar el mayor número de voces posible. Por lo tanto, no podemos dar 10 entradas a una
                        organización. Nos pondremos en contacto con usted para ver cuántas entradas podrá recibir.
                    </p>
                ],
                [
                    <>
                        ¿Qué pasa si la COP26 no se realiza debido a la pandemia? </>,
                    <p>
                        Realmente esperamos que la COP26 se realiza tal y como está prevista, pero por supuesto somos
                        conscientes de que podría no ocurrir. Si tenemos que cancelar el Tren del Clima, reembolsaremos
                        el dinero. </p>
                ],
            ],
        ],
        [
            <>
                El Tren del Clima y Rail to the COP
            </>,
            [

                [
                    <>
                        ¿Qué va a pasar exactamente en el tren?
                    </>,
                    <p>
                        Respuesta corta: ¡mucho! Queremos organizar debates, intercambiar experiencias, finalizar
                        nuestras demandas para la COP26 y mucho más.
                    </p>
                ],
                [
                    <>
                        ¿Puedo unirme al tren pero no participar en el programa? </>,
                    <p>
                        Nuestra visión de este tren es reunirnos, ser capaces de mantener conversaciones con los
                        responsables políticos. Por ello, invitamos a todos a participar en el programa.
                    </p>
                ],

                [
                    <>
                        ¿Quién estará en el tren?
                    </>,
                    <p>
                        Hemos invitado a políticos y funcionarios, a representantes de la industria ferroviaria y de la
                        sociedad civil y a un montón de activistas (jóvenes) para crear una plataforma de intercambio
                        entre todas las partes de la sociedad. Tendremos al menos 300 asientos para representantes de la
                        sociedad civil en el tren.
                    </p>
                ],
                [
                    <>
                        No tengo ninguna experiencia en la acción climática ni en una conferencia de la ONU. ¿Puedo
                        participar?
                    </>,
                    <p>
                        Por supuesto. Queremos ser lo más inclusivos posible e invitar a todo el mundo a participar en
                        este viaje, a conocer a otros activistas y políticos y a trabajar juntos en soluciones para la
                        crisis climática. Y necesitamos a todos, cada voz y cada historia para crear un futuro mejor.
                        Estás cordialmente invitado a unirte al Tren del Clima.
                    </p>
                ],
                [
                    <>
                        ¿El alojamiento está incluido en el billete del Tren del Clima? </>,
                    <p>
                        Lamentablemente, en este momento no podemos ofrecer alojamiento a todos los participantes. Puede
                        reservar una habitación a través de Human Hotel <a
                        href={"https://www.humanhotel.com/cop26/be-a-guest/"}
                        target={"_blank"}>humanhotel.com/cop26/be-a-guest</a>
                    </p>
                ],
                [
                    <>
                        ¿Puedo entrar en la COP con el billete del Tren del Clima? </>,
                    <p>
                        No, para poder entrar en la llamada Zona Azul en la sede de la COP26 hay que estar acreditado
                        por el secretario de la CMNUCC. Nosotros no recibiremos ninguna acreditación. Pero hay
                        increíbles posibilidades de ser activos fuera de la sede de la COP26. La sociedad civil
                        internacional organizará acciones, espacios creativos y otras actividades. Así que no dudes en
                        unirte al Tren del Clima, incluso sin estar acreditado para la COP26.
                    </p>
                ],

                [
                    <>
                        Voy a ir a la COP pero no voy a participar en el Tren del Clima. ¿Dónde puedo hablar con Rail to
                        the COP en el evento de Glasgow?
                    </>,
                    <p>
                        Actualmente estamos trabajando en los eventos de la COP26 en Glasgow. En este momento no podemos
                        decir exactamente dónde estaremos. Si desea colaborar en los eventos o en este proyecto, póngase
                        en contacto con nosotros en info@railtothecop.com.

                    </p>
                ],
            ],
        ],
        [
            <>
                El Viaje
            </>,
            [
                [
                    <>
                        ¿Qué pasa si no consigo un billete? ¿Dónde puedo comprar una alternativa? </>,
                    <p>
                        Puedes comprar un billete con Eurostar para llegar a Londres. En el Reino Unido, la línea de la
                        Costa Oeste de Londres a Glasgow está operada por Avanti, y la de la Costa Este a Edimburgo por
                        LNER. También hay un tren nocturno de Londres a Glasgow: el Caledonian Sleeper.
                        <br/><br/>
                        Las alternativas serían un autobús (por ejemplo Flixbus) o un ferry (por ejemplo DFDS desde
                        IJmuiden a Newcastle).

                    </p>
                ],
                [
                    <>
                        No es posible tomar el tren a la COP desde fuera de Europa. ¿Esperan que personas no europeas
                        sólo asistan a distancia?
                    </>,
                    <p>
                        Definitivamente no, animamos a todo el mundo a participar en la COP26. Lamentablemente, sólo ha
                        sido posible organizar un tren dentro de Europa. Sin embargo, damos la bienvenida a todos en el
                        tren. Así que si vienes de fuera de Europa, puedes venir a Ámsterdam o Bruselas (ver "Si vuelo a
                        Ámsterdam o Bruselas, ¿sigo siendo bienvenido en el tren?") y unirte a nuestro tren allí.

                    </p>
                ],
                [
                    <>
                        ¿Cómo puedo llegar a Ámsterdam/
                        Rotterdam/Bruselas?
                    </>,
                    <p>
                        El Tren del Clima le llevará de Ámsterdam/Rotterdam/Bruselas a Glasgow. Actualmente estamos
                        trabajando en la forma de incluir a personas de toda Europa llevándolas a Bruselas mediante
                        autobuses o trenes. En este momento tienes que reservar tu propio billete a la ciudad donde
                        quieras empezar. Si quieres ayudar y organizar tu propio autobús/tren a Bruselas: ponte en
                        contacto con nosotros a través de info@railtothecop.com. </p>
                ],

                [
                    <>
                        Tengo que viajar a Bruselas. ¿Organizan ustedes el alojamiento allí? </>,
                    <p>
                        No, no podemos organizar el alojamiento en Bruselas.
                    </p>
                ],
                [
                    <>
                        Si vuelo a Ámsterdam o a Bruselas, ¿sigo siendo bienvenido en el tren? </>,
                    <p>
                        Sí. Somos conscientes de que, a estas alturas, la opción de no volar a la COP (especialmente
                        desde fuera de Europa) es un gran privilegio. Pedimos a todos los participantes europeos que
                        viajen en tren o autobús. Los participantes de fuera de Europa son bienvenidos en el tren.
                    </p>
                ],
            ],
        ],
        [
            <>Patrocinio
            </>,
            [
                [
                    <>
                        No puedo pagar un billete, ¿puede patrocinar mi billete Rail to the COP?
                    </>,
                    <p>
                        Todas las personas que no puedan permitirse pagar el billete de tren completo para ir a la COP26
                        con el Tren del Clima pueden pedir que se les patrocine el billete. Queremos que el reembolso
                        sea lo más fácil posible. Lamentablemente, hay que pagar el billete en primer lugar, por razones
                        técnicas. Estamos trabajando con OpenCollective para poder reembolsar el dinero lo antes
                        posible.
                    </p>
                ],
                [
                    <>
                        No puedo pagar el billete por adelantado. ¿Cómo puedo unirme al Tren del Clima?
                    </>,
                    <p>
                        Somos conscientes de que el pago de 70 euros puede ser un obstáculo para que la gente se una. Si
                        no puedes adelantar el dinero, ponte en contacto con nosotros y encontraremos la manera de que
                        puedas reservar el billete. </p>
                ],
                [
                    <>
                        ¿Cómo puedo patrocinar mi billete?
                    </>,
                    <p>
                        Lamentablemente, no es posible pagar un precio inferior durante el proceso de reserva. Por lo
                        tanto, tiene que pagar el billete en primer lugar. Nos hemos asociado con Open Collective para
                        poder reembolsar (parte) del precio del billete. </p>
                ],
                [
                    <>
                        ¿Existe la posibilidad de financiar mi viaje a Bruselas para unirme al Tren del Clima allí?
                    </>,
                    <p>
                        Sí, actualmente estamos buscando formas de reducir el precio / financiar todo el billete. Por
                        favor, indíquenos cuánto podrá pagar por el billete. Esto no afectará al éxito de su solicitud.
                    </p>
                ],
            ],
        ],
    ],
    'de': [

        [
            <>Tickets & Stornierung</>,
            [
                [
                    <>
                        Ich möchte zu einem anderen Datum zur COP reisen, ist das möglich?

                    </>,
                    <p>
                        Wir mussten uns für den Klimazug ein Datum aussuchen, sind uns aber darüber im Klaren, dass
                        einige Leute vielleicht früher oder später zur COP reisen möchten. Leider ist das nicht über
                        Rail to the COP möglich. Natürlich bestärken wir dich trotzdem darin, mit dem Zug anzureisen. Du
                        kannst beispielsweise so nach Glasgow reisen:<br/><br/>
                        Du kannst ein Ticket für den dem Eurostar kaufen, um nach London zu kommen. In Großbritannien
                        wird die Westküstenlinie von London nach Glasgow von Avanti betrieben, und die Ostküstenlinie
                        nach Edinburgh wird von LNER betrieben. Es gibt auch einen Nachtzug von London nach Glasgow: den
                        Caledonian Sleeper.<br/><br/>
                        Alternativen wären ein Bus (zum Beispiel Flixbus) oder eine Fähre (zum Beispiel DFDS von
                        IJmuiden nach Newcastle).

                    </p>
                ],
                [
                    <>
                        Wie viel kosten die Tickets?
                    </>,
                    <p>
                        Die regulären Preise für die Fahrt von Amsterdam, Rotterdam oder Brüssel nach Glasgow betragen
                        70 € in der 2. Klasse und 120 € in der 1. Klasse. Du kannst auch ein Hin- und Rückfahrtticket
                        von London nach Brüssel, Rotterdam oder Amsterdam zu einem ermäßigten Tarif buchen, musst aber
                        Deine Reise von Glasgow nach London selbst buchen.
                    </p>
                ],
                [
                    <>
                        Wie flexibel ist mein Ticket?
                    </>,
                    <p>
                        Eurostar erstattet Dein Ticket vollständig, wenn Du dich entscheidest, Deine Reise bis zu 7 Tage
                        vor der Reise zu stornieren. Außerdem ist Dein Rückfahrtticket von London nach Brüssel,
                        Rotterdam oder Amsterdam bis zu 24 Stunden vor der Abfahrt umtauschbar.
                    </p>
                ],
                [
                    <>
                        Kann ich mehrere Tickets für meine Organisation kaufen?
                    </>,
                    <p>
                        Bitte gib uns einen Hinweis, wie viele Personen aus Deiner Organisation am Klimazug teilnehmen
                        möchten. Wir möchten so viele Stimmen wie möglich zu Wort kommen lassen. Daher können wir nicht
                        10 Tickets an eine Organisation vergeben. Wir werden uns mit Dir in Verbindung setzen, um zu
                        sehen, wie viele Tickets Du erhalten kannst.
                    </p>
                ],
                [
                    <>
                        Was passiert, wenn die COP26 aufgrund der Pandemie nicht stattfindet?
                    </>,
                    <p>
                        Wir hoffen wirklich, dass die COP26 wie geplant stattfinden wird, aber wir sind uns natürlich
                        bewusst, dass das nicht passieren könnte. Wenn wir den Klimazug absagen müssen, werden wir das
                        Geld zurückerstatten.
                    </p>
                ],
            ],
        ],
        [
            <>Der Klimazug/Climate Train und Rail to the COP
            </>,
            [

                [
                    <>
                        Was genau wird im Zug passieren?
                    </>,
                    <p>
                        Kurze Antwort: eine Menge! Wir wollen Diskussionen organisieren, Erfahrungen austauschen, unsere
                        Forderungen für COP26 finalisieren und vieles mehr. Aktuell arbeiten wir am Programm. Folge uns
                        daher am besten auf Social Media, dann bekommst Du alles mit.
                    </p>
                ],
                [
                    <>
                        Kann ich mit dem Zug mitfahren, aber nicht am Programm teilnehmen?
                    </>,
                    <p>
                        Unsere Vision für diesen Zug ist es, zusammenzukommen und Gespräche mit politischen
                        Entscheidungsträger*innen führen zu können. Daher laden wir alle herzlich ein, am Programm
                        teilzunehmen.
                    </p>
                ],

                [
                    <>
                        Wer wird im Zug mitfahren?
                    </>,
                    <p>
                        Wir haben Politiker*innen, Vertreter*innen der Bahnindustrie und der Zivilgesellschaft und jede
                        Menge (Jugend-)Aktivist*innen eingeladen, um eine Plattform für einen Austausch zwischen allen
                        Teilen der Gesellschaft zu schaffen. Wir werden mindestens 300 Plätze für die Zivilgesellschaft
                        im Zug haben.
                    </p>
                ],
                [
                    <>
                        Ich habe keine Erfahrung mit Klimaaktivismus oder den UN-Konferenzen. Kann ich trotzdem
                        mitmachen? </>,
                    <p>
                        Ja, natürlich. Wir wollen so inklusiv wie möglich sein und laden alle ein, an dieser Reise
                        teilzunehmen, andere Aktivist*innen und Politiker*innen kennenzulernen und gemeinsam an Lösungen
                        für die Klimakrise zu arbeiten. Und wir brauchen jede Stimme und jede Geschichte, um eine
                        bessere Zukunft zu schaffen. Du bist herzlich eingeladen, dich dem Klimazug anzuschließen.
                    </p>
                ],
                [
                    <>
                        Ist die Unterkunft im Ticket für den Klimazug enthalten?
                    </>,
                    <p>
                        Leider können wir zum jetzigen Zeitpunkt nicht für alle Teilnehmer*innen eine Unterkunft
                        anbieten. Du kannst ein Zimmer über Human Hotel <a
                        href={"https://www.humanhotel.com/cop26/be-a-guest/"}
                        target={"_blank"}>humanhotel.com/cop26/be-a-guest</a> buchen.
                    </p>
                ],
                [
                    <>
                        Kann ich die COP mit dem Klimazug-Ticket betreten?
                    </>,
                    <p>
                        Nein, um die sogenannte Blaue Zone am Veranstaltungsort der COP26 betreten zu können, musst Du
                        vom UNFCCC-Sekretariat akkreditiert werden. Wir selbst werden keine Akkreditierungen erhalten.
                        Aber es gibt tolle Möglichkeiten, auch außerhalb des COP26 Veranstaltungsortes aktiv zu werden.
                        Die internationale Zivilgesellschaft wird Aktionen, kreative Räume und andere Aktivitäten
                        organisieren. Fühl dich also frei, dich dem Klimazug anzuschließen, auch ohne für die COP26
                        akkreditiert zu sein.
                    </p>
                ],

                [
                    <>
                        Ich fahre zur COP, aber nicht mit dem Klimazug. Wo kann ich mit Rail to the COP bei der
                        Veranstaltung in Glasgow sprechen?
                    </>,
                    <p>
                        Wir arbeiten derzeit an Veranstaltungen auf der COP26 in Glasgow. Zu diesem Zeitpunkt können wir
                        nicht genau sagen, wo wir sein werden. Wenn Du an Veranstaltungen oder diesem Projekt
                        mitarbeiten möchtest, kontaktiere uns bitte unter info@railtothecop.com.

                    </p>
                ],
            ],
        ],
        [
            <>Die Reise
            </>,
            [
                [
                    <>
                        Was ist, wenn ich kein Ticket bekommen habe? Wo sollte ich am besten eine Alternative kaufen?
                    </>,
                    <p>
                        Du kannst ein Ticket mit dem Eurostar kaufen, um nach London zu gelangen. In Großbritannien wird
                        die Westküstenlinie von London nach Glasgow von Avanti betrieben, und die Ostküstenlinie nach
                        Edinburgh wird von LNER betrieben. Es gibt auch einen Nachtzug von London nach Glasgow: den
                        Caledonian Sleeper.<br/><br/>
                        Alternativen wären ein Bus (zum Beispiel Flixbus) oder eine Fähre (zum Beispiel DFDS von
                        IJmuiden nach Newcastle).
                    </p>
                ],
                [
                    <>
                        Es ist nicht möglich, von außerhalb Europas mit dem Zug zur COP zu fahren. Erwartet ihr, dass
                        Nicht-Europäer*innen nur aus der Ferne teilnehmen?

                    </>,
                    <p>
                        Definitiv nicht, wir ermutigen alle, an der COP26 teilzunehmen. Leider war es nur möglich, einen
                        Zug innerhalb Europas zu organisieren. Dennoch heißen wir alle im Zug herzlich willkommen. Wenn
                        Du also von außerhalb Europas kommst, könntest Du nach Amsterdam oder Brüssel kommen (siehe
                        "Wenn ich nach Amsterdam oder Brüssel fliege, bin ich trotzdem im Zug willkommen?") und Dich
                        dort unserem Zug anschließen.

                    </p>
                ],
                [
                    <>
                        Wie komme ich nach Amsterdam/Rotterdam/Brüssel?
                    </>,
                    <p>
                        Der Klimazug wird Dich von Amsterdam/Rotterdam/Brüssel nach Glasgow bringen. Wir arbeiten
                        derzeit an Möglichkeiten, Menschen aus ganz Europa einzubeziehen, indem wir sie mit Bussen oder
                        Zügen nach Brüssel bringen. Zu diesem Zeitpunkt musst Du Dein eigenes Ticket zu der Stadt
                        buchen, von der aus Du starten möchtest. Willst Du mithelfen und Deinen eigenen Bus/Zug nach
                        Brüssel organisieren: melde Dich bei uns über info@railtothecop.com.
                    </p>
                ],

                [
                    <>
                        Ich muss nach Brüssel reisen. Organisiert ihr dort eine Unterkunft?
                    </>,
                    <p>
                        Nein, wir sind nicht in der Lage, eine Unterkunft in Brüssel zu organisieren.
                    </p>
                ],
                [
                    <>
                        Wenn ich nach Amsterdam oder Brüssel fliege, bin ich dann trotzdem im Zug willkommen?
                    </>,
                    <p>
                        Ja! Wir sind uns bewusst, dass die Möglichkeit, nicht zur COP zu fliegen (insbesondere von
                        außerhalb Europas), zu diesem Zeitpunkt ein großes Privileg ist. Wir bitten alle europäischen
                        Teilnehmer*innen, mit dem Zug oder Bus anzureisen. Teilnehmer*innen von außerhalb Europas sind
                        aber natürlich trotzdem im Zug herzlich willkommen.
                    </p>
                ],
            ],
        ],
        [
            <>Finanzierung
            </>,
            [
                [
                    <>
                        Ich kann mir kein Ticket leisten, kann mein Ticket von Rail to the COP gesponsert werden?
                    </>,
                    <p>
                        Alle, die es sich nicht leisten können, das volle Bahnticket zu bezahlen, um mit dem Klimazug
                        zur COP26 zu fahren, können darum bitten, das Ticket gesponsert zu bekommen. Wir wollen die
                        Rückerstattung so einfach wie möglich machen. Leider musst Du das Ticket aus technischen Gründen
                        erst selbst bezahlen. Wir arbeiten mit OpenCollective zusammen, um das Geld so schnell wie
                        möglich zurückerstatten zu können.
                    </p>
                ],
                [
                    <>
                        Ich kann das Ticket nicht im Voraus bezahlen. Wie kann ich trotzdem am Klimazug teilnehmen?

                    </>,
                    <p>
                        Wir sind uns bewusst, dass die Zahlung von 70 € ein Hindernis für Menschen sein kann, um
                        mitzumachen. Wenn Du das Geld nicht vorstrecken kannst, melde Dich bitte bei uns und wir werden
                        einen Weg finden, wie Du das Ticket buchen kannst. Schreib uns dazu einfach eine E-Mail an
                        info@railtothecop.com.
                    </p>
                ],
                [
                    <>
                        Wie kann mein Ticket gesponsert werden?
                    </>,
                    <p>
                        Leider ist es nicht möglich, während des Buchungsvorgangs einen niedrigeren Preis zu zahlen.
                        Daher musst Du das Ticket zunächst bezahlen. Wir arbeiten mit OpenCollective zusammen, um (Teile
                        des) Ticketpreises erstatten zu können.
                    </p>
                ],
                [
                    <>
                        Gibt es eine Möglichkeit, meine Reise nach Brüssel zu finanzieren, um dort am Klimazug
                        teilzunehmen?
                    </>,
                    <p>
                        Ja, wir arbeiten derzeit an Möglichkeiten, den Preis zu senken / das gesamte Ticket zu
                        finanzieren. Bitte gib uns einen Hinweis, wie viel Du für das Ticket bezahlen kannst. Dies wird
                        den Erfolg Deiner Bewerbung nicht beeinflussen.
                    </p>
                ],
            ],
        ],
    ],
    'fr': [

        [
            <>
                Billets et annulation
            </>,
            [
                [
                    <>
                        Je souhaite me rendre à la COP à une autre date. Est-ce possible ?
                    </>,
                    <p>
                        Nous avons dû fixer la date à laquelle le Train du Climat fera son voyage, mais nous comprenons
                        que certains souhaitent se rendre à la COP plus tôt ou plus tard. Nous vous encourageons bien
                        sûr à effectuer votre trajet en train. Vous pouvez aisément réserver vos billets :
                        <br/><br/>
                        Vous pouvez obtenir un billet avec Eurostar pour vous rendre à Londres. Au Royaume-Uni, la ligne
                        West Coast de Londres à Glasgow est opérée par Avanti, et la ligne East Coast vers Edinburgh est
                        opérée par LNER. Il existe aussi un train de nuit de Londres à Glasgow : le Caledonian Sleeper.
                        <br/><br/>
                        Vous pouvez aussi considérer d’autres alternatives par bus (par exemple Flixbus) ou en ferry
                        (par exemple DFDS de IJmuiden à Newcastle).

                    </p>
                ],
                [
                    <>
                        Combien coûte le billet ?
                    </>,
                    <p>
                        Le prix de base du trajet depuis Amsterdam, Rotterdam ou Bruxelles vers Glasgow est de €70 en
                        classe standard et de €120 en classe business. Vous aurez aussi la possibilité de réserver un
                        retour depuis Londres vers Bruxelles, Rotterdam ou Amsterdam à un tarif préférentiel, mais vous
                        devrez réserver votre voyage de Glasgow à Londres par vos propres moyens.
                    </p>
                ],
                [
                    <>
                        Le billet est-il échangeable et/ou remboursable ? </>,
                    <p>
                        Eurostar s’engage à rembourser intégralement votre ticket en cas d’annulation jusqu’à 7 jours
                        avant le départ. De plus, votre billet retour de Londres vers Bruxelles, Rotterdam ou Amsterdam
                        sera échangeable jusqu’à 24 heures avant votre départ.
                    </p>
                ],
                [
                    <>
                        Puis-je réserver plusieurs billets pour mon organisation ?
                    </>,
                    <p>
                        Veuillez nous indiquer combien de personnes de votre organisation souhaitent voyager à bord du
                        Train du Climat. Nous souhaitons garantir une diversité de voix différentes à bord, c’est
                        pourquoi nous ne pouvons pas attribuer plus de 10 billets par organisation. Nous vous
                        contacterons dès que possible afin de vous informer de combien de billets vous seront
                        attribué·e·s.
                    </p>
                ],
                [
                    <>
                        Que se passe-t-il si la COP26 n’a pas lieu en raison de la pandémie ?
                    </>,
                    <p>
                        Nous espérons sincèrement que la COP26 aura lieu comme prévu, mais nous avons conscience que
                        ceci est incertain. Si le Train du Climat venait à être annulé, nous vous rembourserons
                        intégralement.
                    </p>
                ],
            ],
        ],
        [
            <>
                Le Train du Climat et Rail to the COP
            </>,
            [

                [
                    <>
                        Qu’est-il prévu exactement à bord du train ?
                    </>,
                    <p>
                        La version courte : beaucoup de choses ! Nous organiseront des discussions, des échanges
                        d’expérience, nous finaliserons nos demandes pour la COP26 et plein d’autres surprises encore.
                    </p>
                ],
                [
                    <>
                        Puis-je monter à bord du train mais ne pas participer au programme ?
                    </>,
                    <p>
                        Notre vision pour ce train est un espace où divers acteurs sont rassemblés et échangent avec des
                        décideurs politiques. Aussi, nous invitons chaudement tous nos voyageurs à participer au
                        programme.
                    </p>
                ],

                [
                    <>
                        Qui sera à bord du train ?

                    </>,
                    <p>
                        Nous avons d’ores et déjà invité des fonctionnaires et décideurs publiques, des membres
                        représentants du secteur ferroviaire et de la société, et plein de jeunes activistes pour créer
                        une plateforme d’échange entre divers acteurs de la société. Nous réservons au moins 300 places
                        pour des représentants de la société civile à bord du train.

                    </p>
                ],
                [
                    <>
                        Je n’ai jamais participé à une action pour le climat et je n’ai pas d’expérience avec les
                        conférences de l’ONU. Puis-je participer ?
                    </>,
                    <p>
                        Bien sûr ! Nous souhaitons être aussi inclusifs que possible, et inviter des personnes de tous
                        horizons à participer à ce voyage, à rencontrer d’autres activistes et politiques, et à
                        travailler ensemble pour trouver des solutions à la crise climatique. Pour créer un futur
                        meilleur, nous avons besoin de toutes les voix et de toutes les histoires. C’est pourquoi vous
                        êtes tous les bienvenus à bord du Train du Climat.
                    </p>
                ],
                [
                    <>
                        Est-ce que l’hébergement à Glasgow est inclus dans le prix du billet du Train du Climat ?
                    </>,
                    <p>
                        Malheureusement, nous ne pouvons pas garantir de solution d’hébergement à tous les participants.
                        Vous pouvez réserver une chambre via Human Hotel <a
                        href={"https://www.humanhotel.com/cop26/be-a-guest/"}
                        target={"_blank"}>humanhotel.com/cop26/be-a-guest</a> buchen.
                    </p>
                ],
                [
                    <>
                        Puis-je avoir accès à la COP26 avec mon billet du Train du Climat , </>,
                    <p>
                        Non, pour avoir accès à la “Blue Zone” de la COP26, il faut être accrédité par le secrétariat de
                        la CCNUCC. Nous mêmes ne recevront pas d’accréditation. Mais les opportunités d’être actif en
                        dehors de la COP26 sont nombreuses. La société civile internationale organise tout un tas
                        d’actions, d'espaces de créations et d’activités en tout genre. Vous êtes donc les bienvenus à
                        bord du Train du Climat, même sans accréditation pour la COP26.
                    </p>
                ],

                [
                    <>
                        Je me rends à la COP26, mais ne prendrai pas le Train du Climat. Comment puis-je retrouver Rail
                        to the COP à Glasgow ?
                    </>,
                    <p>
                        Nous travaillons actuellement à préparer des événements pour la COP26 à Glasgow. Nous ne pouvons
                        pour l’instant pas confirmer où ils se situeront. Si vous souhaitez nous joindre, et contribuer
                        à un événement particulier ou au projet en général, contactez-nous à info@railtothecop.com

                    </p>
                ],
            ],
        ],
        [
            <>
                Voyager
            </>,
            [
                [
                    <>
                        Et si je n'obtiens pas de billet ? Comment trouver la meilleure alternative ?
                    </>,
                    <p>
                        Vous pouvez obtenir un billet avec Eurostar pour vous rendre à Londres. Au Royaume-Uni, la ligne
                        West Coast de Londres à Glasgow est opérée par Avanti, et la ligne East Coast vers Edinburgh est
                        opérée par LNER. Il existe aussi un train de nuit de Londres à Glasgow : le Caledonian Sleeper.
                        <br/><br/>
                        Vous pouvez aussi considérer d’autres alternatives par bus (par exemple Flixbus) ou en ferry
                        (par exemple DFDS de IJmuiden à Newcastle).
                    </p>
                ],
                [
                    <>
                        Il n’est pas possible de voyager à la COP26 en train depuis un pays en dehors de l’Europe. Cela
                        signifie-t-il que vous attendez des participants non-européens qu’ils suivent la COP à distance
                        ?

                    </>,
                    <p>
                        Absolument pas, nous encourageons tout le monde à assister à la COP26. Malheureusement, il ne
                        nous était possible d’organiser un voyage en train que depuis l’Europe. Cependant, nous
                        accueillons aussi avec plaisir des personnes non-européennes à bord du train. Si vous venez d’en
                        dehors de l’Europe, vous pouvez vous rendre à Amsterdam ou Bruxelles (voir “Si je me rends à
                        Amsterdam ou Bruxelles en avion, suis-je bienvenu·e dans le train ?”) et joindre notre train de
                        là.

                    </p>
                ],
                [
                    <>
                        Comment me rendre à Amsterdam/Rotterdam/Bruxelles ?
                    </>,
                    <p>
                        Le Train du Climat vous emmènera de Amsterdam/Rotterdam/Bruxelles jusqu’à Glasgow. Nous
                        travaillons actuellement à offrir la possibilité à des passagers venus de toute l’Europe de
                        rejoindre Bruxelles en bus ou en train. Mais pour l’instant, vous devrez réserver votre propre
                        billet depuis votre ville de départ. Voulez-vous aider à organiser un bus ou un train en
                        destination de Bruxelles ? Alors contactez-nous à info@railtothecop.com.
                    </p>
                ],

                [
                    <>
                        Je dois me rendre à Bruxelles, prévoyez-vous des hébergements là-bas ?
                    </>,
                    <p>
                        Non, nous ne sommes pas en mesure d’organiser des solutions d’hébergement à Bruxelles.
                    </p>
                ],
                [
                    <>
                        Si je me rends à Amsterdam ou Bruxelles en avion, suis-je bienvenu·e dans le train ?
                    </>,
                    <p>
                        Oui ! Nous sommes conscients que pour l’instant, c’est un privilège de se rendre à la COP sans
                        prendre l’avion (en particulier pour des personnes venant d’en dehors de l’Europe). Nous
                        demandons à toutes les personnes venant d’Europe de faire leur maximum pour voyager en train ou
                        en bus. Les participants venant d’en dehors de l’Europe sont les bienvenus à bord de notre
                        train.
                    </p>
                ],
            ],
        ],
        [
            <>
                Parrainage financier
            </>,
            [
                [
                    <>
                        Je n’ai pas les moyens financiers d’acheter un billet, puis-je être parrainé·e financièrement
                        par Rail to the COP ?
                    </>,
                    <p>
                        Toute personne qui n’a pas les moyens de payer le prix standard du billet peut demander à être
                        parrainé·e. Malheureusement, pour des raisons techniques, vous devez payer votre ticket en
                        premier lieu, puis nous ferons notre possible pour vous rembourser aussi rapidement que
                        possible. Nous collaborons avec OpenCollective pour vous rembourser de manière rapide et
                        efficace.
                    </p>
                ],
                [
                    <>
                        Je ne peux pas payer le billet en avance, puis-je quand même prendre le Train du Climat ?

                    </>,
                    <p>
                        Nous sommes conscients que €70 peuvent être une barrière financière pour certaines personnes. Si
                        vous ne pouvez pas payer ce montant en avance, nous vous invitons à nous contacter, et nous
                        trouverons un moyen de réserver votre billet pour vous.
                    </p>
                ],
                [
                    <>
                        Comment puis-je être parrainé·e ?
                    </>,
                    <p>

                        Malheureusement, nous ne pouvons pas offrir de tarif réduit sur la plateforme de réservation.
                        C’est pourquoi vous devez payer plein-tarif en premier lieu. Nous collaborons avec Open
                        Collective pour rembourser (au moins une partie) du prix du billet.

                    </p>
                ],
                [
                    <>
                        Est-il possible de parrainer mon voyage jusqu’à Bruxelles, avant de monter à bord du Train du
                        Climat ?

                    </>,
                    <p>
                        Oui, nous travaillons actuellement à réduire le prix du billet voire à couvrir totalement les
                        frais du billet. Cela n'impactera pas le processus de sélection.
                    </p>
                ],
            ],
        ],
    ],
};

const FormFAQ = () => {

    const {language} = useLanguage();
    let activeLanguage = languages[language];
    const {t} = useTranslation();
    const [activeIndex, setActiveIndex] = useState(-1);
    const handleClick = (_, {index}) => {
        setActiveIndex(index === activeIndex ? -1 : index)
    }

    let questionsLanguage = activeLanguage.tag;
    if (!questions[questionsLanguage]) questionsLanguage = "en";

    return <>
        <h2>{t("join.faq-heading")}</h2>

        <Grid stackable className={"faq"}>
            <Grid.Row>
                {questions[questionsLanguage].map((qs, j) => <Grid.Column width={8} key={"qa" + j}>
                    <h3>{qs[0]}</h3>
                    <Accordion fluid styled>
                        {qs[1].map((q, i) => <div key={"q" + i + j * 100}>
                                <Accordion.Title
                                    active={activeIndex === i + j * 100}
                                    index={i + j * 100}
                                    onClick={handleClick}
                                    className={"faq-title"}
                                >
                                    {q[0]}
                                </Accordion.Title>
                                <Accordion.Content
                                    active={activeIndex === i + j * 100}
                                    className={"faq-content"}
                                >
                                    {q[1]}
                                </Accordion.Content>
                            </div>
                        )}
                    </Accordion><br/>
                </Grid.Column>)}
            </Grid.Row>
        </Grid>
    </>;
}

export default FormFAQ;