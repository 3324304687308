import React from 'react';

function PrivacyPolicy() {
    return (
        <div className="sail">


            <div className="ui vertical stripe segment padded first colored-background">
                <div className="ui top aligned stackable grid container centered">
                    <div className="row">
                        <div className="column sixteen wide">
                            <h1>Privacy Policy</h1>

                            <h2 className="c5" id="h.yk4ii4flgr38"><span className="c8">Privacy</span></h2>
                            <p>
                                By using this website and/or applying for The Climate Train, you agree to the
                                collection, use, and storage of
                                information in relation with this policy. The information that we collect are used for
                                administrative and legal purposes. We will not use or share your information with anyone
                                except as described.
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">When you join The Climate Train, you provide us information, which we collect, use and store. The data include but are not limited to:</span>
                            </p>
                            <ul className="c6 lst-kix_bcq1majgvdyg-0 start">
                                <li className="c0"><span className="c1">Your name</span></li>
                                <li className="c0"><span className="c1">Your address</span></li>
                                <li className="c0"><span className="c1">Your e-mail address</span></li>
                                <li className="c0"><span className="c1">Your date of birth</span></li>
                                <li className="c0"><span className="c1">Your gender</span></li>
                                <li className="c0"><span
                                    className="c1">Your identification with underrepresented groups</span></li>
                                <li className="c0"><span className="c1">Personal travel preferences</span></li>
                            </ul>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">
                                We only require these data from you in order to organize The Climate Train. These data are stored for a period of no longer then 12 months and deleted thereafter. We do not place cookies or log information about your visit to this website.
                            </span>
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">
                                We value your trust in providing us your Personal Information, thus we strive to protect your information and use those means that will protect your data the best within commercially acceptable limits. But remember that no method of transmission over the internet, or method of electronic storage is 100% secure and reliable, and we cannot guarantee the absolute security of your personal information.
                            </span>
                            </p>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span className="c1">
                                Our Services do not address anyone of the age of 17 or younger. We do not knowingly collect personal identifiable information from children of the age of 17 or younger. In the case we discover that a child of the age of 17 or younger has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware that your child has provided us with personal information, we kindly ask you to contact us so that we will be able to do necessary actions.
                            </span>
                            </p>
                            <h3 className="c14" id="h.1c4elt4ym7pm"><span className="c7">Partners</span></h3>
                            <p className="c4"><span className="c1">
                                The Climate Train is organized together with our partners, we will share your personal information with select people from these organizations:
                            </span>
                            </p>
                            <ul className="c6 lst-kix_bcq1majgvdyg-0 start">
                                <li className="c0"><span className="c1">Eurostar</span></li>
                                <li className="c0"><span className="c1">ProRail</span></li>
                                <li className="c0"><span className="c1">NS</span></li>
                            </ul>
                            <h3 className="c14" id="h.1c4elt4ym7pm"><span className="c7">Service Providers</span></h3>
                            <p className="c4"><span className="c1">We employ third-party companies and individuals for the following reasons:</span>
                            </p>
                            <ol className="c6 lst-kix_3ywc2t8t8xqb-0 start" start="1">
                                <li className="c0"><span className="c1">To facilitate our Service;</span></li>
                                <li className="c0"><span className="c1">To perform Service-related services;</span></li>
                            </ol>
                            <p className="c3"><span className="c1"></span></p>
                            <p className="c4"><span>We want to inform our users that these third parties may have access to your Personal Information. The reason is to perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</span>
                            </p>
                            <p>Payments are processed by <a href="https://stripe.com/us/privacy/" target="_blank">Stripe,
                                Inc.</a>. Payment data is not stored on our servers and you are therefore referred to
                                the <a href="https://stripe.com/us/privacy/" target="_blank">privacy statement</a> of
                                Stripe to learn more about the privacy of your payment information.</p>
                            <p>We store personal data at <span
                                className="c2"><a className="c9"
                                                  href="https://www.google.com/url?q=https://firebase.google.com/&amp;sa=D&amp;ust=1539014596691000">Firebase by Google</a></span><span>, which you can read more about </span><span
                                className="c2"><a className="c9"
                                                  href="https://www.google.com/url?q=https://firebase.google.com/support/privacy/&amp;sa=D&amp;ust=1539014596691000">at Google</a></span><span>&nbsp;(including </span><span
                                className="c2"><a className="c9"
                                                  href="https://www.google.com/url?q=https://firebase.google.com/terms/data-processing-terms&amp;sa=D&amp;ust=1539014596691000">the data processing agreement</a></span><span
                                className="c1">).</span></p>
                            <p>Additionally, we store personal data at Google Sheets</p>
                            <h2 className="c5" id="h.jea8b88zsxup"><span className="c8">Contact Us</span></h2>
                            <p className="c4"><span className="c1">If you have any questions, complaints or suggestions about our Privacy Policy, do not hesitate to contact us at info@railtothecop.com.</span>
                            </p>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default PrivacyPolicy;
